
export const jobCategories = [
    '飲食店スタッフ',
    '調理',
    '専門・技術',
    'WEB・デザイナー',
    'ライター',
    '観光・ツアー',
    '運送・配達',
    '美容',
    '営業・販売',
    '教師・講師',
    'オーペア',
    'クリーナー・ハウスキーパー',
    'ファーム',
    'その他',
]

export const workTypes = [
    'フルタイム',
    'パートタイム',
    'カジュアル・フリーランス'
]

export const englishLevels = [
    '初級（挨拶程度）',
    '中級（簡単な意思疎通）',
    '上級（仕事で問題無く使える）',
    'ネイティブ並'
]

export const tagNames = [
    '未経験OK',
    '短期可',
    'まかない付き',
    'ワーホリ歓迎',
    'ワークビザサポート'
]
