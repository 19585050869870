import React, { useEffect } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import HelpPersonal from './HelpPersonal';
import HelpBusiness from './HelpBusiness';

const Help = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    const defaultIndex = currentUser && currentUser.profile.user_type == "Business" ? 1 : 0;

    return (
        <div>
            <Helmet>
                <title>ご利用方法</title>
                <meta name="description" content="ニュージーワークスの使い方を求職者用アカウント・企業・雇用主用アカウントに分けて解説しています。" />
            </Helmet>
            <div className="pageHeader">
                <h1 className="heading">ご利用方法</h1>
            </div>
            <div className="helpPage container">
                <Tabs defaultIndex={defaultIndex}>
                    <div className="tabs is-boxed control">
                        <TabList >
                            <Tab selectedClassName="is-active"><a href="# ">仕事をお探し中の方</a></Tab>
                            <Tab selectedClassName="is-active"><a href="# ">求人広告を投稿したい方</a></Tab>
                        </TabList>
                    </div>
                    <TabPanel>
                        <HelpPersonal />
                    </TabPanel>
                    <TabPanel>
                        <HelpBusiness />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default Help