import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import formatDate from '../../utils/formatDate';
import Spinner from '../common/Spinner';
import Pagination from '../common/Pagination';
import { getSavedJobList } from '../../actions/adminActions';

const DEFAULT_PAGE_SIZE = 50;

const AdminSavedJobList = (props) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setPage] = useState(1);

    const dispatch = useDispatch();
    const savedJobList = useSelector(state => state.admin.savedJobList);
    const itemCount = useSelector(state => state.admin.savedJobCount);

    useEffect(() => {
        setLoading(true);
        dispatch(getSavedJobList(currentPage, DEFAULT_PAGE_SIZE))
    }, [currentPage, setLoading, getSavedJobList, dispatch])

    useEffect(() => {
        setLoading(false);
    }, [savedJobList, setLoading])

    const handlePageClick = selectedPage => {
        setPage(selectedPage);
    }

    const renderJobList = () => {
        if (savedJobList.length === 0) return <tr><td>No data</td></tr>

        return savedJobList.map(savedJob => {
            return (
                <tr key={savedJob._id}>
                    <td>{savedJob.jobTitle}</td>
                    <td>{savedJob.employerName}</td>
                    <td>{savedJob.jobStatus}</td>
                    <td>{savedJob.areaName}</td>
                    <td>{savedJob.user ? savedJob.user.name : 'N/A'}</td>
                    <td>{formatDate(savedJob.created_at)}</td>
                    <td>{savedJob.applied ? 'applied' : 'saved'}</td>
                    <td>{formatDate(savedJob.applied_at)}</td>
                </tr>
            )
        })
    }

    return (
        <div className={props.className}>
            <div className="adminSavedJobList">
                {
                    loading ? <Spinner /> : <table className="adminTable"><tbody>{renderJobList()}</tbody></table>
                }
            </div>
            <Pagination
                itemCount={itemCount}
                pageSize={DEFAULT_PAGE_SIZE}
                onPageChange={handlePageClick}
                className='bg-secondary-light'
            />
        </div>
    )
}

export default AdminSavedJobList
