import React from 'react'
import AdminJobList from './AdminJobList';

const JobPanel = () => {
    return (
        <div>
            <AdminJobList />
        </div>
    )
}

export default JobPanel
