import React from 'react'
import PropTypes from 'prop-types';

import formatDate from '../../utils/formatDate';

const PersonalProfileCard = props => {
    const { user } = props;
    // let introduction = user.profile.introduction.length > 200 ? user.profile.introduction.substr(0, 200) + '...' : user.profile.introduction;

    return (
        <div className={`personalProfileCard  ${props.className}`} >
            <h3 className="businessProfileCard__name has-text-info">{user.name}  <span className="tag is-primary">{user.profile.area ? user.profile.area.name : 'エリア指定無し'}</span></h3>
            <p className="u-margin-bottom-small"><b>更新日:</b> {formatDate(user.updated_at)}</p>
            <div className="u-text-wrap">
                {user.profile.introduction}
            </div>
        </div>
    )
}

PersonalProfileCard.propTypes = {
    user: PropTypes.object
}

export default PersonalProfileCard
