import { ADMIN_SET_USER_LIST, ADMIN_SET_JOB_LIST, ADMIN_SET_SAVED_JOB_LIST } from '../actions/types';

const INITIAL_STATE = {
    userList: [],
    userCount: 0,
    jobList: [],
    jobCount: 0,
    savedJobList: [],
    savedJobCount: 0,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_SET_USER_LIST:
            return {
                ...state,
                userList: action.payload.userList,
                userCount: action.payload.userCount
            }
        case ADMIN_SET_JOB_LIST:
            return {
                ...state,
                jobList: action.payload.jobList,
                jobCount: action.payload.jobCount
            }
        case ADMIN_SET_SAVED_JOB_LIST:
            return {
                ...state,
                savedJobList: action.payload.savedJobList,
                savedJobCount: action.payload.savedJobCount
            }
        default:
            return state;
    }
}
