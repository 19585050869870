import React, { useEffect } from "react";
import useForm, { FormContext } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../actions/userActions";

import AreaField from "../../form/AreaField";
import TextField from "../../form/TextField";
import SwitchField from "../../form/SwitchField";

const PersonalProfileEdit = props => {
  const dispatch = useDispatch();
  const methods = useForm();
  const { setValue, register } = methods;
  const currentUser = useSelector(state => state.user.currentUser);

  useEffect(() => {
    if (currentUser) {
      let profile = currentUser.profile;
      setValue("name", currentUser.name);
      setValue("introduction", profile.introduction);
      setValue("area", profile.area);
      setValue("is_public", profile.is_public);
      setValue("allowEmail", profile.allowEmail);
    }
  }, [currentUser, setValue, register]);

  const onSubmit = data => {
    let fd = { ...data };
    fd.user_type = "Personal";
    dispatch(updateProfile(fd));
  };

  return (
    <div className="container">
      <FormContext {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <TextField
            label="表示名"
            type="text"
            placeholder="表示名を入力してください"
            name="name"
            className="field u-margin-bottom-medium"
            registerOptions={{ required: true, maxLength: 50, minLength: 2 }}
          />
          <TextField
            label="自己主紹介文(1000文字以内)"
            type="textarea"
            name="introduction"
            className="field u-margin-bottom-medium"
            rows={15}
            registerOptions={{ minLength: 6, maxLength: 1000 }}
          />
          <AreaField
            label="希望勤務地"
            name="area"
            className="field u-margin-bottom-medium"
          />
          <SwitchField
            label="プロフィールを公開する"
            name="is_public"
            className="field u-margin-small u-padding-small has-background-light"
            info="※ON にすると企業・雇用者アカウントユーザーがプロフィールの参照、及びメール送信ができるようになります (求職アカウントユーザーからは見えません)"
          />

          <div className="control editSection">
            <button className="button is-link is-large">変更を保存</button>
          </div>
        </form>
      </FormContext>
    </div>
  );
};

export default PersonalProfileEdit;
