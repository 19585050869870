import React from 'react'
import { Helmet } from "react-helmet";

const Privacy = () => {
    return (
        <div className="section">
            <Helmet>
                <title>個人情報保護方針</title>
                <meta name="description" content="個人情報保護方針" />
            </Helmet>
            <h1 className="heading u-margin-bottom-medium">個人情報保護方針</h1>
            <div className="container">
                <p>管理者は、当サービスの運用において、会員から提供されたメールアドレス等の個人情報の取り扱いについて細心の注意を払います。</p>
            </div>
            <div className="container">

                <h2>個人情報の提供・開示について</h2>
                <p>管理者は、保有する利用者の個人情報について、利用者の事前の同意を得ずに本来の目的以外の目的でこれを利用することはありません。また、利用者の個人情報を他の業者等に提供することもありません。</p>
                <p>
                    ただし、法令等に基づき提供に応じなければならない等、やむを得ない場合にはこの限りではございません。</p>
            </div>
            <div className="container">

                <h2>登録情報の利用目的</h2>
                <p>当サービスを利用する際に提供された個人情報は、以下の用途で使用されます。</p>
                <ol>
                    <li>アカウント認証</li>
                    <li>パスワード再発行</li>
                    <li>当サイトのコンタクトフォーム経由で求人に応募があった時のご連絡メール</li>
                    <li>規約違反行為が見受けられた際の警告メール</li>
                    <li>お問い合わせに対する返答</li>
                </ol>
                <p>
                    登録に使用したメールアドレスが当サイト上に表示される事はございません。
            </p>
            </div>
            <div className="container">
                <h2>クッキーについて</h2>
                <p>当サービスにはクッキーを利用しているものがあります。クッキーは、ウェブサイトが閲覧者のブラウザに一定の情報を保存する仕組です。これらの情報は個人を特定するものでは無く、当サービスへのアクセス情報を分析することにより、サービスの改善に役立てております。</p>
                <p>閲覧者は、クッキーの使用禁止やクッキーが使われたことを毎回閲覧者に知らせる設定をブラウザで行なうことができます。</p>
            </div>
            <br />
            <p>2020年 1月5日制定</p>
        </div>
    )
}

export default Privacy;
