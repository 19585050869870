
import { SET_LOADING, SET_AREA_LIST, SET_CANDIDATE_COUNT } from './types';
import client from '../utils/client';


export const setLoading = (flag = true) => {
    return {
        type: SET_LOADING,
        payload: flag
    }
}

const setAreaList = list => {
    return {
        type: SET_AREA_LIST,
        payload: list
    }
}

const setCandidateCount = count => {
    return {
        type: SET_CANDIDATE_COUNT,
        payload: count
    }
}

export const getCandidateCount = () => dispatch => {
    client.get('user/candidate_count').then(response => {
        if (response.data) {
            dispatch(setCandidateCount(response.data));
        }
    }).catch(error => {
        console.log(error);
    })
}

export const getAreaList = () => dispatch => {
    client.get('area')
        .then(response => {
            dispatch(setAreaList(response.data));
        })
        .catch(error => {
            console.log(error);
        })
}