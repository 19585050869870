import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import formatDate from '../../utils/formatDate';
import Image from '../common/Image';
import Icon from '../common/Icon';
import noImageUrl from '../../img/no_image_small.jpg';

const ThreadCard = ({ thread }) => {
    const renderUserInfo = (user) => {
        return (
            <div className="threadCard__userInfo">
                <figure className="threadCard__userInfo__image u-margin-small">
                    {
                        user.profile.avatar ? <Image src={user.profile.avatar.image_url} size={'200'} /> : <img src={noImageUrl} />
                    }
                </figure>
                <p><b>{user.name}</b></p>
            </div>
        )
    }

    return (
        <Link to={`/thread/${thread._id}`} className="threadCard">
            {
                thread.mainImage ? (
                    <div className="threadCard__image">
                        <Image src={thread.mainImage.image_url} size={'200'} />
                    </div>
                ) : null
            }
            <div className="threadCard__content">
                <h3>{thread.title}</h3>
                <div className="u-flex u-flex-align">
                    <div className="u-flex-grow">
                        {renderUserInfo(thread.user)}
                    </div>
                </div>
                <hr />
                <div className="u-flex u-flex-align u-flex-wrap">
                    <span className="u-flex-grow">投稿日:<b>{formatDate(thread.created_at)}</b></span>
                    <span><Icon iconClassName="fas fa-comment" className="has-text-primary u-margin-left-small" />x <b>{thread.comments.length}</b></span>
                </div>
            </div>
        </Link>
    )
}

ThreadCard.propTypes = {
    thread: PropTypes.object.isRequired
}

export default ThreadCard

