import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../history';
import { checkNotifications } from '../../actions/userActions';
import moment from 'moment';
import 'moment/locale/ja';
import Spinner from '../common/Spinner';

Modal.setAppElement("#root")

const Notifications = () => {
    const user = useSelector(state => state.user.currentUser);
    const notifications = useSelector(state => state.user.notifications);
    const loading = useSelector(state => state.common.loading);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            //Make all notification to be checked
            dispatch(checkNotifications());
        };
    }, [dispatch])

    const handleClick = notification => {
        switch (notification.notificationType) {
            case "like":
                // setUserId(notification.recordId);
                history.push('/profiles/' + notification.recordId);
                break;
            case "comment":
                history.push('/thread/' + notification.recordId);
                break;
            default:
                break;
        }
    }

    const renderNotifications = () => {
        if (!notifications || notifications.length === 0)
            return <div>お知らせはありません</div>;

        let userNotifications = [...notifications];

        return userNotifications.map((notification, index) => {
            return (
                <div key={index} className={`notificationList__item ${notification.recordId ? 'hover-basic' : ''} ${notification.checked ? 'checked' : ''}`} onClick={() => handleClick(notification)}>
                    <div className="u-text-wrap">{notification.message}</div>
                    <p className="u-margin-top-small has-text-info">{moment(notification.created_at).utc().fromNow()}</p>
                </div>
            )
        })
    }

    return (
        <div className="container">
            <h1 className="heading u-margin-medium">お知らせ一覧</h1>
            <div className="notificationList">
                {loading ? <Spinner /> : renderNotifications()}
            </div>
        </div>
    )
}

export default Notifications
