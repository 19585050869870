import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import useForm, { FormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addNewJob, updateJob } from "../../actions/jobActions";
import { setLoading } from "../../actions/commonActions";

import {
  jobCategories,
  workTypes,
  tagNames,
  englishLevels
} from "../../constants/jobFilterValues";

import client from "../../utils/client";
import Alert from "../../utils/alert";
import getAreaFromLocation from "../../utils/getAreaFromLocation";
import { resizeFile } from "../../utils/imageManager";

import Spinner from "../common/Spinner";
import SelectField from "../form/SelectField";
import TextField from "../form/TextField";
import TagField from "../form/TagField";
import AreaField from "../form/AreaField";
import AddressField from "../form/AddressField";
import MainImageSelector from "../form/MainImageSelector";
import { TWEET_INTERVAL } from "../../constants";

function JobEdit(props) {
  const methods = useForm();
  const { setValue, getValues, watch, register } = methods;
  const dispatch = useDispatch();
  const [initialImageUrl, setInitialImageUrl] = useState(null);
  const [tweetedAt, setTweetedAt] = useState(null);
  const loading = useSelector(state => state.common.loading);
  const user = useSelector(state => state.user.currentUser);
  const areaList = useSelector(state => state.common.areaList);

  const jobLocation = watch("location");
  const jobId = props.match.params.id;

  useEffect(() => {
    register({ name: "mainImage" });

    if (jobId) {
      dispatch(setLoading(true));
      client
        .get("job/" + jobId)
        .then(response => {
          setValue("title", response.data.title);
          // setValue("mainImage", response.data.mainImage);
          setInitialImageUrl(
            response.data.mainImage ? response.data.mainImage.image_url : null
          );
          setValue("details", response.data.details);
          setValue("area", response.data.area ? response.data.area._id : "");
          setValue("jobCategory", response.data.jobCategory);
          setValue("workType", response.data.workType);
          setValue("englishLevel", response.data.englishLevel);
          setValue("tags", response.data.tags);
          setValue("address", response.data.address);
          setValue("email", response.data.email);
          setValue("phone", response.data.phone);
          setTweetedAt(response.data.tweetedAt);
        })
        .catch(error => {
          Alert.error("詳細データの取得に失敗しました");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  }, [jobId, register, setValue, dispatch]);

  useEffect(() => {
    if (!user) return;

    let { address, location } = user.profile;
    if (address) {
      setValue("address", address);
    }
    if (location) {
      setValue("location", {
        lat: location.coordinates[1],
        lng: location.coordinates[0]
      });
    }
    if (user.profile.phone) {
      setValue("phone", user.profile.phone);
    }
    setValue("email", user.email);
  }, [user, setValue]);

  useEffect(() => {
    if (jobLocation) {
      let area = getAreaFromLocation(areaList, jobLocation);
      if (area) {
        setValue("area", area._id);
      }
    }
  }, [setValue, jobLocation, areaList]);

  const handleFileChange = file => {
    setValue("mainImage", file);
  };

  const enableTweet = () => {
    if (!user.profile.companyVerified) return false;
    if (!tweetedAt) return true;

    let timeDiff = Date.now() - new Date(tweetedAt).getTime();
    return timeDiff > TWEET_INTERVAL;
  };

  const onSubmit = async formData => {
    let fd = new FormData();

    for (var dataKey in formData) {
      let data = formData[dataKey];
      switch (dataKey) {
        case "area":
          if (data) {
            fd.append("area", data);
          }
          break;
        case "mainImage":
          if (data) {
            let resizedFile = await resizeFile(data, 1024, data.name);
            fd.append("photo", resizedFile, resizedFile.name);
          }
          break;
        case "tags":
          if (data) {
            data.forEach(tag => {
              fd.append("tags[]", tag);
            });
          }
          break;
        case "location":
          fd.append("lng", data ? data.lng : "");
          fd.append("lat", data ? data.lat : "");
          break;
        default:
          fd.append(dataKey, data ? data : "");
      }
    }

    if (jobId) {
      dispatch(updateJob(jobId, fd, enableTweet()));
    } else {
      dispatch(addNewJob(fd, user.profile.companyVerified));
    }
  };

  return (
    <div className="container jobEdit">
      {loading ? <Spinner cover={true} /> : null}
      <h1 className="heading u-margin-medium">求人広告編集</h1>
      <div className="warningBox">
        <p>
          企業のホームページやSNS（Facebook,
          インスタグラムページ）等をお持ちの場合、
          <b>求人投稿をする前に企業紹介の設定をする事をお勧めします。</b>
          （企業紹介に設定した項目は求人広告に自動的に表示されます）
        </p>
        <br />
        <p>
          企業情報の設定は右上メニューより『企業紹介編集』を選択し、各種変更を行ってください。
        </p>
      </div>
      <FormContext {...methods}>
        <form className="form" onSubmit={methods.handleSubmit(onSubmit)}>
          <TextField
            label="タイトル"
            type="text"
            className="field u-margin-medium"
            placeholder="求人広告のタイトルを入力してください"
            name="title"
            registerOptions={{ required: true, maxLength: 100 }}
          />
          <div className="field u-margin-medium">
            <label className="label">メイン画像</label>
            <MainImageSelector
              onFileChange={handleFileChange}
              initialImageUrl={initialImageUrl}
            />
          </div>
          <TextField
            label="詳細"
            type="textarea"
            placeholder="求人の詳細を入力してください"
            name="details"
            className="field field u-margin-medium"
            rows={20}
            registerOptions={{ required: true, maxLength: 10000 }}
          />
          <div className="u-flex-responsive">
            <div
              className="field u-margin-medium u-flex-grow"
              style={{ maxWidth: "90rem" }}
            >
              <section className="section has-background-light u-margin-bottom-small">
                <h3>この求人の連絡手段</h3>
                <div className="u-flex u-flex-wrap">
                  <TextField
                    label="連絡先メールアドレス"
                    type="email"
                    className="field u-margin-small"
                    placeholder="メールアドレスを入力してください"
                    name="email"
                    registerOptions={{ maxLength: 100, required: true }}
                  />
                  <TextField
                    label="連絡先電話番号"
                    type="phone"
                    className="field u-margin-small"
                    placeholder="電話番号を入力してください"
                    name="phone"
                    registerOptions={{ maxLength: 100 }}
                  />
                </div>
              </section>
              <section className="section has-background-light">
                <h3>その他詳細</h3>
                <div className="u-flex u-flex-wrap">
                  <SelectField
                    label="職種"
                    name="jobCategory"
                    className="field u-margin-small"
                    emptyString="-- 選択してください --"
                    dataList={jobCategories}
                    registerOptions={{ required: true }}
                  />
                  <SelectField
                    label="雇用形態"
                    name="workType"
                    className="field u-margin-small"
                    emptyString="不問"
                    dataList={workTypes}
                  />
                  <SelectField
                    label="必要英語力"
                    name="englishLevel"
                    className="field u-margin-small"
                    emptyString="不問"
                    dataList={englishLevels}
                  />
                </div>
                <TagField
                  label="アピールポイント（複数選択可）"
                  name="tags"
                  className="field"
                  dataList={tagNames}
                />
              </section>
            </div>
            <div className="u-margin-small" style={{ minWidth: "30rem" }}>
              <section className="section has-background-light">
                <h3>勤務先</h3>
                <AddressField
                  label="住所"
                  placeholder="勤務先の住所を入力してください"
                />
                <AreaField
                  label="エリア"
                  name="area"
                  className="field u-margin-small"
                />
              </section>
            </div>
          </div>
          <div className="field u-margin-small">
            <div className="control">
              <button
                type="submit"
                className="button is-primary is-large u-margin-auto"
              >
                {jobId ? "更新する" : "求人を追加する"}
              </button>
            </div>
          </div>
        </form>
      </FormContext>
    </div>
  );
}

export default JobEdit;
