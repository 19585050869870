import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import UserPanel from './UserPanel';
import JobPanel from './JobPanel';
import { SavedJobPanel } from './SavedJobPanel';

const Dashboard = () => {

    return (
        <div className="container">
            <h1 className="u-margin-small">Admin dashboard</h1>
            <Tabs>
                <div className="tabs is-boxed control">
                    <TabList >
                        <Tab selectedClassName="is-active"><a href="# ">Users</a></Tab>
                        <Tab selectedClassName="is-active"><a href="# ">Jobs</a></Tab>
                        <Tab selectedClassName="is-active"><a href="# ">Saved Jobs</a></Tab>
                    </TabList>
                </div>
                <TabPanel>
                    <h2>Users</h2>
                    <UserPanel />
                </TabPanel>
                <TabPanel>
                    <h2>Jobs</h2>
                    <JobPanel />
                </TabPanel>
                <TabPanel>
                    <h2>Saved Jobs</h2>
                    <SavedJobPanel />
                </TabPanel>
            </Tabs>
        </div>
    )
}

export default Dashboard
