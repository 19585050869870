
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

//Current page should be set to global state
function GlobalNav(props) {
    const currentUser = useSelector(state => state.user.currentUser);
    const candidateCount = useSelector(state => state.common.candidateCount);
    const userType = currentUser ? currentUser.profile.user_type : null;
    const isVerified = currentUser ? currentUser.verified : false;
    const renderLinks = () => {
        let linkContents = null;
        if (userType === 'Business' && isVerified) {
            linkContents = (
                <Fragment>
                    <Link to="/personalprofiles" className="globalNav__item">求職者リスト{candidateCount > 0 ? `(${candidateCount})` : ''}</Link>
                </Fragment>
            )
        } else if (userType === 'Personal') {
            linkContents = (
                <Fragment>
                    <Link to="/mypage/savedjoblist" className="globalNav__item">保存リスト</Link>
                </Fragment>
            )
        }


        return (
            <Fragment>
                <Link to="/jobs" className="globalNav__item">求人検索</Link>
                <Link to="/profiles" className="globalNav__item">企業紹介</Link>
                <Link to="/thread" className="globalNav__item">自由投稿板</Link>
                {linkContents}
            </Fragment>
        )
    }

    return (
        <nav className="globalNav">
            {renderLinks()}
        </nav>
    )
}

export default GlobalNav;