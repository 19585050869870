import React, { useState } from 'react';
import { validateImage } from '../../utils/imageManager';
import Alert from '../../utils/alert';

const MainImageSelector = ({onFileChange, initialImageUrl = null}) => {
    const [imagePreviewUrl, setUrl] = useState(null);

    const handleFileChange = e => {
        let file = e.target.files[0];

        if (!validateImage(file)) {
            Alert.error("対応していないファイルタイプです");
            setUrl(null);
            onFileChange(null);
            return;
        }

        let reader = new FileReader();

        reader.onloadend = () => {
            setUrl(reader.result)
        }

        reader.readAsDataURL(file);
        //Parent
        onFileChange(file);
    }

    const handleClear = () => {
        setUrl(null);
        onFileChange(null);
    }

    const renderImage = () => {
        if( imagePreviewUrl) return <img src={imagePreviewUrl} alt="preview" />
        
        return initialImageUrl ? <img src={initialImageUrl} alt="preview" /> : "No Image"
    }

    return (
        <div className="control mainImageSelector">
            <div className="mainImageSelector__preview">
                {
                    renderImage()
                }
            </div>
            <input className="input"
                type="file"
                name="mainImage"
                accept="image/*"
                onChange={handleFileChange}
            />
            <button onClick={handleClear} type="button" className="button is-danger is-small u-margin-small">選択解除</button>
        </div>
    )
}

export default MainImageSelector
