import client from '../utils/client';
import Alert from '../utils/alert';
import errorToStr from '../utils/errorToStr';

import {
    MAIL_SET_RECORD_INFO,
    MAIL_SET_SENDING,
    MAIL_SET_ERRORS
} from './types';

const setMailRecordInfo = recordInfo => {
    return {
        type: MAIL_SET_RECORD_INFO,
        payload: recordInfo
    }
}

const setMailSending = flag => {
    return {
        type: MAIL_SET_SENDING,
        payload: flag
    }
}

const setMailErrors = errors => {
    return {
        type: MAIL_SET_ERRORS,
        payload: errors
    }
}

export const setRecordInfo = (recordInfo) => dispatch => {
    dispatch(setMailRecordInfo(recordInfo));
}

export const snedMail = (recordType, recordId, fd, cb) => dispatch => {
    dispatch(setMailSending(true));
    dispatch(setMailErrors(null));

    client.post(`${recordType}/${recordId}/mail`, fd)
        .then(res => {
            Alert.success("メールを送信しました");
            cb();
        })
        .catch(error => {
            console.log(error);
            dispatch(setMailErrors(errorToStr(error)));
        })
        .finally(() => {
            dispatch(setMailSending(false));
        })
}
