import { SET_JOB_DETAILS, SET_USER_JOB_LIST } from "../actions/types";

const INITIAL_STATE = {
  jobDetails: null,
  userJobList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.payload
      };
    case SET_USER_JOB_LIST:
      return {
        ...state,
        userJobList: action.payload
      };
    default:
      return state;
  }
};
