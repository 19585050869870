import React, { useState } from 'react'
import HelpPanel from './HelpPanel';
import HelpRegister from './pages/HelpRegister';
import HelpBusinessProfile from './pages/HelpBusinessProfile';
import HelpBusinessJobEdit from './pages/HelpBusinessJobEdit';
import HelpBusinessJobUpdate from './pages/HelpBusinessJobUpdate';

const HelpBusiness = () => {
    const [accountOpen, setAccountOpen] = useState(false);
    const [profileEditOpen, setProfileEditOpen] = useState(false);
    const [jobEditOpen, setJobEditOpen] = useState(false);
    const [jobUpdateOpen, setJobUpdateOpen] = useState(false);

    return (
        <div>
            <h2 className="heading--label-primary">企業・雇用主アカウント</h2>
            <div onClick={() => setAccountOpen(!accountOpen)} className="u-margin-medium">
                <HelpPanel title="アカウント登録" show={accountOpen} >
                    <HelpRegister business={true} />
                </HelpPanel>
            </div>
            <div onClick={() => setProfileEditOpen(!profileEditOpen)} className="u-margin-medium">
                <HelpPanel title="企業紹介編集" show={profileEditOpen} >
                    <div className="info">
                        <p>
                            企業紹介は設定しなくても求人投稿は可能ですが、設定していないと『企業紹介』ページにプロフィールが表示されなくなります。
                        </p>
                        <p>
                            ここで設定された項目は求人広告にも自動的に表示されます。内容の重複を避ける為、ここではあくまで企業・雇用主の紹介文やウェブサイトのリンクのみを記載し、求人内容は記述しない事をお勧めします。
                        </p>
                    </div>
                    <HelpBusinessProfile />
                </HelpPanel>
            </div>
            <div onClick={() => setJobEditOpen(!jobEditOpen)} className="u-margin-medium">
                <HelpPanel title="求人投稿・編集" show={jobEditOpen} >
                    <HelpBusinessJobEdit />
                </HelpPanel>
            </div>
            <div onClick={() => setJobUpdateOpen(!jobUpdateOpen)} className="u-margin-medium">
                <HelpPanel title="求人の再告知" show={jobUpdateOpen} >
                    <div className="info">
                        <p>
                            <b className="has-text-danger">更新日が古い求人広告は読まれ難い傾向があります。</b>
                        </p>
                        <p>
                            その為、継続的に求人を募集する際は定期的に求人再告知を行う事をお勧めします。（その際更新日は自動的に変更されます）
                        </p>
                    </div>
                    <HelpBusinessJobUpdate />
                </HelpPanel>
            </div>
        </div>
    )
}

export default HelpBusiness
