import React, { useEffect } from 'react'

export default function EmailSent() {
    let email = sessionStorage.getItem('email');

    return (
        <div className="container" style={{ paddingTop: '5rem' }}>
            <p>登録されたEメールアドレス{email ? `(${email})` : ''}に認証用URLを送信致しました。</p>
            <p>メール本文に記載されたリンクをクリックして、アカウントの認証を行ってください。</p>
            <br />
            <p className="has-text-info">※認証リンクは24時間のみ有効です</p>
            <p className="has-text-info">※メールが届かなかった場合、迷惑メールに振り分けられていないかご確認ください</p>
            <p className="has-text-info">※通信状況によってはメールが届くまで5分程度かかる事がございます。もし長時間待っても届かない場合は、恐れ入りますが問い合わせフォームからご連絡ください。</p>
        </div>
    )
}
