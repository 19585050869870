import React from 'react';
import { Link } from 'react-router-dom';
import srcSelectMenu from '../../../img/help/help_select_menu.jpg';
import srcBusinessProfileMenu from '../../../img/help/help_business_profile_menu.jpg';
import srcBusinessProfileSave from '../../../img/help/help_business_profile_save.jpg';
import srcBusinessProfileOnJobDetail from '../../../img/help/help_business_profile_on_jobdetail.jpg';

const HelpBusinessProfile = () => {
    return (
        <div>
            <div className="line">・画面右上、メニューをクリックまたはタップします</div>
            <div className="image">
                <img src={srcSelectMenu} />
            </div>
            <hr />
            <div className="line">・メニューから『企業紹介編集』を選択し、編集ページへ移動します。</div>
            <div className="image">
                <img src={srcBusinessProfileMenu} />
            </div>
            <hr />
            <div className="line">・『企業紹介』ページで表示する為の画像、紹介文、ウェブサイトアドレス等を設定した後、画面下にある『変更を保存』ボタンを押して変更内容を保存してください。</div>
            <div className="image">
                <img src={srcBusinessProfileSave} />
            </div>
            <div className="line has-text-info">※ここで設定された画像と紹介文は、求人広告の方にも自動的に表示されます。</div>
            <div className="image">
                <img src={srcBusinessProfileOnJobDetail} />
            </div>
            <div className="line has-text-info"><b>※このページで画像を最低一つアップロードし、且つ企業・雇用主紹介文を記入する事によって『<Link to="/profiles">企業紹介</Link>』ページに企業情報が表示されるようになります。</b></div>
        </div>
    )
}

export default HelpBusinessProfile
