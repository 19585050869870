import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getThreadList } from '../../actions/threadActions';
import Spinner from '../common/Spinner';
import ThreadList from './ThreadList';

const ThreadHome = () => {
    const dispatch = useDispatch();
    const [created, setCreated] = useState(false);
    const threadList = useSelector(state => state.thread.threadList);
    const user = useSelector(state => state.user.currentUser);
    const loading = useSelector(state => state.common.loading);

    useEffect(() => {
        dispatch(getThreadList());
    }, [dispatch])

    useEffect(() => {
        if (threadList && user) {
            if (threadList.findIndex(thread => thread.user._id === user._id) >= 0) {
                setCreated(true);
            }
            else {
                setCreated(false);
            }
        }
    }, [threadList, user, setCreated])

    return (
        <div>
            <Helmet>
                <title>自由投稿板</title>
                <meta name="description" content="企業アカウント、求職アカウント共にご利用可能な簡易情報投稿機能です。全ての投稿は30日更新が無いと自動的に消去されます。" />
            </Helmet>
            <div className="pageHeader">
                <h1>自由投稿板</h1>
            </div>
            <div className="container">
                <div className="infoBox">
                    {
                        user && user.profile.user_type === "Business"
                            ? (
                                <b><p className="has-text-danger">企業アカウントにて求人投稿をする場合は、右上『メニュー』⇨求人投稿•編集より行ってください。自由投稿板に投稿された内容は求人検索に表示されません。</p><br /></b>
                            ) : null
                    }
                    <p>企業アカウント、求職アカウント共にご利用可能な簡易情報投稿機能です。</p>
                    <br />
                    <p className="has-text-info">※現在は特に書き込む内容に制限はございません。ただし利用規約に反するような書き込みがあった場合には告知なく削除させて頂く事もございます。</p>
                    <p className="has-text-info">※１アカウントにつき1つだけ投稿できます。別の内容の投稿をする際は、既存の投稿を削除して新規に作成してください。</p>
                    <p className="has-text-info">※全ての投稿は30日更新が無いと自動的に消去されます。</p>
                </div>
                {
                    !user || created ? null
                        : (
                            <div className="has-text-center">
                                <Link to="/thread/edit" className="button is-medium u-margin-medium is-primary">新しい情報を投稿する</Link>
                            </div>
                        )
                }
                {
                    loading ? <Spinner cover={true} /> : <ThreadList threads={threadList} />
                }
            </div>
        </div>
    )
}

export default ThreadHome
