import client from '../utils/client';
import Alert from '../utils/alert';
import errorToStr from '../utils/errorToStr';
import { SET_LATEST_JOBLIST, SET_LATEST_FEEDLIST, SET_REGISTERED_COMPANY_LIST, SET_LATEST_POSTLIST } from './types';
import { setLoading } from './commonActions';


const setLatestJobList = jobList => {
    return {
        type: SET_LATEST_JOBLIST,
        payload: jobList
    }
}

const setLatestFeedList = feedList => {
    return {
        type: SET_LATEST_FEEDLIST,
        payload: feedList
    }
}

const setLatestPostList = postList => {
    return {
        type: SET_LATEST_POSTLIST,
        payload: postList
    }
}

const setRegisteredCompanyList = companyList => {
    return {
        type: SET_REGISTERED_COMPANY_LIST,
        payload: companyList
    }
}

export const getLatestFeedList = () => dispatch => {
    const params = {
        limit: 1,
    };

    client.get('feed', { params: params }).then(response => {
        if (response.data[0]) {
            dispatch(setLatestFeedList(response.data[0].jobList));
        }
    }).catch(error => {
        console.log(error);
    })
}

export const getLatestPostList = () => dispatch => {
    const params = {
        limit: 5,
    };

    client.get('thread', { params: params }).then(response => {
        dispatch(setLatestPostList(response.data ? response.data : []));
    }).catch(error => {
        console.log(error);
    })
}

export const getLatestJobList = () => dispatch => {
    dispatch(setLoading());
    const params = {
        limit: 12,
    };

    client.get('job', { params: params }).then(response => {
        dispatch(setLatestJobList(response.data));
    }).catch(error => {
        Alert.error(errorToStr(error));
    })
        .finally(() => {
            dispatch(setLoading(false));
        })
}

export const getRegisteredCompanyList = (hideEmpty = true) => dispatch => {
    let params = {
        page: 1,
        size: 10,
        hide_empty: hideEmpty
    }

    client.get('user', { params: params })
        .then(res => {
            const { users } = res.data;
            dispatch(setRegisteredCompanyList(users));
        })
        .catch(error => {
            console.log(error);
            Alert.error(errorToStr(error));
        })
}
