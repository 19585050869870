import React from 'react'
import AdminUserList from './AdminUserList';
import ResendVerifyEmail from './ResendVerifyEmail';
import SendNotifications from './SendNotifications';

const UserPanel = () => {
    return (
        <div>
            <div>
                <h2>Resend verify email</h2>
                <ResendVerifyEmail />
            </div>
            <div>
                <h2>Send Notifications</h2>
                <SendNotifications />
            </div>
            <div>
                <h2>User List</h2>
                <AdminUserList />
            </div>
        </div>
    )
}

export default UserPanel
