import client from '../utils/client';
import { ADMIN_SET_USER_LIST, ADMIN_SET_JOB_LIST, ADMIN_SET_SAVED_JOB_LIST } from './types';

const DEFAULT_PAGE_SIZE = 12;

const setUserList = (userList, itemCount) => {
    return {
        type: ADMIN_SET_USER_LIST,
        payload: { userList: userList, userCount: itemCount }
    }
}

const setJobList = (jobList, itemCount) => {
    return {
        type: ADMIN_SET_JOB_LIST,
        payload: { jobList: jobList, jobCount: itemCount }
    }
}

const setSavedJobList = (savedJobList, itemCount) => {
    return {
        type: ADMIN_SET_SAVED_JOB_LIST,
        payload: { savedJobList: savedJobList, savedJobCount: itemCount }
    }
}

export const getUserList = (page = 1, size = DEFAULT_PAGE_SIZE, options = {}) => dispatch => {
    let params = {
        page: page,
        size: size
    }

    if (options.unverified) {
        params.unverified = true;
    }

    client.get('admin/users', { params: params })
        .then(res => {
            const { count, users } = res.data;
            dispatch(setUserList(users, count));
        })
        .catch(error => {
            console.log(error);
        })
}

export const getJobList = (page = 1, size = DEFAULT_PAGE_SIZE, options = {}) => dispatch => {
    let params = {
        page: page,
        size: size
    }

    if (options.unverified) {
        params.unverified = true;
    }

    client.get('admin/jobs', { params: params })
        .then(res => {
            const { count, jobs } = res.data;
            dispatch(setJobList(jobs, count));
        })
        .catch(error => {
            console.log(error);
        })
}

export const getSavedJobList = (page = 1, size = DEFAULT_PAGE_SIZE, options = {}) => dispatch => {
    let params = {
        page: page,
        size: size
    }

    client.get('admin/savedjobs', { params: params })
        .then(res => {
            const { count, savedJobs } = res.data;
            dispatch(setSavedJobList(savedJobs, count));
        })
        .catch(error => {
            console.log(error);
        })
}

