import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BusinessProfileCardMin from '../profile/BusinessProfileCardMin';
import Icon from '../common/Icon';

const RegisteredCompaniesInfo = () => {
    const registeredCompanyList = useSelector(state => state.home.registeredCompanyList)

    const renderProfileList = () => {
        if (registeredCompanyList.length === 0) {
            return <div>No data</div>
        }

        return registeredCompanyList.map(profile => {
            return <div key={profile._id} className="registeredCompanyList__item hover-basic">
                <Link to={`/profiles/${profile._id}`}>
                    <BusinessProfileCardMin user={profile} />
                </Link>
            </div>
        })
    }

    return (
        <div className="registeredCompanyList">
            <div>
                {renderProfileList()}
            </div>
            <Link className="has-text-link" to="/profiles"> <Icon iconClassName="fas fa-chevron-circle-right" /> 登録企業一覧へ</Link>
        </div>
    )
}

export default RegisteredCompaniesInfo
