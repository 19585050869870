import React from 'react';
import srcSelectMenu from '../../../img/help/help_select_menu.jpg';
import srcBusinessJobMenu from '../../../img/help/help_business_job_menu.jpg';
import srcBusinessJobAdd from '../../../img/help/help_business_job_add.jpg';

const HelpBusinessJobEdit = () => {
    return (
        <div>
            <div className="line">・画面右上、メニューをクリックまたはタップします</div>
            <div className="image">
                <img src={srcSelectMenu} />
            </div>
            <hr />
            <div className="line">・メニューから『求人投稿・編集』を選択し、編集ページへ移動します。</div>
            <div className="image">
                <img src={srcBusinessJobMenu} />
            </div>
            <hr />
            <div className="line">『求人広告を作成する』ボタンをクリックし、編集ページへ移動します。</div>
            <div className="image">
                <img src={srcBusinessJobAdd} />
            </div>
            <hr />
            <div><p>メイン画像や求人の詳細等各項目を記入した後、画面下にある『求人を追加する』ボタンを押すと求人広告が投稿されます。</p>
                <p>求人広告の編集、削除、一時停止及び再開といった事も同ページから行う事ができます。</p></div>
        </div>
    )
}

export default HelpBusinessJobEdit
