import React from 'react'
import AdminSavedJobList from './AdminSavedJobList';

export const SavedJobPanel = () => {
    return (
        <div>
            <AdminSavedJobList />
        </div>
    )
}
