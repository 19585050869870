import { SET_LATEST_JOBLIST, SET_LATEST_FEEDLIST, SET_REGISTERED_COMPANY_LIST, SET_LATEST_POSTLIST } from '../actions/types';

const INITIAL_STATE = {
    latestJobList: [],
    latestFeedList: [],
    latestPostList: [],
    registeredCompanyList: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LATEST_JOBLIST:
            return {
                ...state,
                latestJobList: action.payload
            }
        case SET_LATEST_FEEDLIST:
            return {
                ...state,
                latestFeedList: action.payload
            }
        case SET_LATEST_POSTLIST:
            return {
                ...state,
                latestPostList: action.payload
            }
        case SET_REGISTERED_COMPANY_LIST:
            return {
                ...state,
                registeredCompanyList: action.payload
            }
        default:
            return state;
    }
}
