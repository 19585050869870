import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from '../../actions/authActions';
import { getNotifications } from '../../actions/userActions';
import Image from '../common/Image';
import Icon from '../common/Icon';
import history from '../../history';
import Alert from '../../utils/alert';
import MenuIcon from '../common/Icons/MenuIcon';

let notificationTimer;

function AuthHeader(props) {
    const ref = useRef();
    const dispatch = useDispatch();
    const [menuActive, setMenuActive] = useState(false);
    const currentUser = useSelector(state => state.user.currentUser);
    const notifications = useSelector(state => state.user.notifications);

    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            setMenuActive(false);
        }
    }

    var timeoutConst;

    const handleMouseOut = () => {
        timeoutConst = setTimeout(() => {
            setMenuActive(false);
        }, 1000)
    }

    const handleMouseOver = () => {
        clearTimeout(timeoutConst);
    }

    const checkNotifications = () => {
        if (currentUser) {
            dispatch(getNotifications());
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClick);
        notificationTimer = setInterval(checkNotifications, 1000 * 60 * 5); //5min

        return () => {
            document.removeEventListener("click", handleClick);
            clearInterval(notificationTimer);
        }
    })

    const handleLogout = () => {
        dispatch(signOut());
        setMenuActive(false);
        history.push('/');
        Alert.success("ログアウトしました");
    }

    const getNotificationNum = () => {
        if (!currentUser || !notifications || notifications.length === 0)
            return 0;

        return notifications.filter(n => !n.checked).length;
    }

    const renderBusinessMenu = () => {
        return (
            <Fragment>
                <Link to="/mypage/profile"><li><Icon iconClassName="fas fa-building" className="has-text-info" /> 企業紹介編集</li></Link>
                <Link to="/mypage/postedjoblist"><li><Icon iconClassName="fas fa-clipboard-list" className="has-text-info" /> 求人投稿・編集</li></Link>
            </Fragment>
        )
    }

    const renderPersonalMenu = (notificationNum) => {
        return (
            <Fragment>
                <Link to="/mypage/profile"><li><Icon iconClassName="fas fa-user" className="has-text-info" /> プロフィール編集</li></Link>
                <Link to="/mypage/savedjoblist"><li><Icon iconClassName="fas fa-file-alt" className="has-text-info" /> 保存リスト</li></Link>
            </Fragment>
        )
    }

    const renderMenu = (user, notificationNum) => {
        return (
            <ul>
                {
                    user.is_admin
                        ? <Link to='/admin' ><li>Admin</li></Link>
                        : null
                }
                {
                    user.profile.user_type === 'Business'
                        ? renderBusinessMenu()
                        : renderPersonalMenu(notificationNum)
                }
                <Link to="/notifications"><li><Icon iconClassName="fas fa-bell" className="has-text-info" /> お知らせ{notificationNum > 0 ? <span className="u-margin-left-small badge">{notificationNum}</span> : null}</li></Link>
                <Link to="/mypage/account"><li><Icon iconClassName="fas fa-cog" className="has-text-info" /> アカウント設定</li></Link>
                <Link to="/help"><li><Icon iconClassName="fas fa-question-circle" className="has-text-info" /> ヘルプ</li></Link>
                <li onClick={handleLogout}><Icon iconClassName="fas fa-sign-out-alt" className="has-text-info" /> ログアウト</li>
            </ul>
        )
    }

    const renderGuestMenu = () => {
        return <ul>
            <Link to="/signin"><li><Icon iconClassName="fas fa-sign-in-alt" className="has-text-info" /> ログイン</li></Link>
            <Link to="/signup"><li><Icon iconClassName="fas fa-user-plus" className="has-text-info" /> 新規登録</li></Link>
            <Link to="/help"><li><Icon iconClassName="fas fa-question-circle" className="has-text-info" /> ヘルプ</li></Link>
        </ul>
    }

    const renderLinks = () => {
        let notificationNum = getNotificationNum();

        return (
            <Fragment>
                <div className="authHeader__menuIcon u-margin-medium has-text-centered">
                    <MenuIcon className="is-large u-margin-top-small" onClick={() => setMenuActive(true)} />
                    <p className="has-text-info" style={{ fontSize: '1.4rem' }}><b>メニュー</b></p>
                    {
                        notificationNum > 0
                            ? <div className="authHeader__badge">{notificationNum}</div>
                            : null
                    }
                </div>
                <div className={`authHeader__menu ${menuActive ? 'active' : ''}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <div className="authHeader__menu__header"><div>メニュー</div><Icon iconClassName="fas fa-times icon--selectable" className="is-medium fa-lg" onClick={() => setMenuActive(false)} /></div>
                    {currentUser ? renderMenu(currentUser, notificationNum) : renderGuestMenu()}
                </div>
            </Fragment>
        )
    }

    const renderUserInfo = () => {
        return <Fragment>
            {
                currentUser.profile && currentUser.profile.avatar && currentUser.profile.avatar.image_id
                    ? (
                        <figure className="image">
                            <Image src={currentUser.profile.avatar.image_url} size={'200'} alt={currentUser.name} />
                        </figure>
                    )
                    : null
            }
            <span className="authHeader__name">{currentUser.name}</span>
        </Fragment>
    }

    return (
        <div className="authHeader" ref={ref}>
            {currentUser ? renderUserInfo() : null}
            {renderLinks(currentUser)}
        </div>
    )
}

export default AuthHeader;
