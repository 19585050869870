import client from "../utils/client";
import Alert from "../utils/alert";
import errorToStr from "../utils/errorToStr";
import history from "../history";
import {
  SET_JOB_SEARCH_FILTER,
  SET_JOB_SEARCH_RESULT,
  SET_CURRENT_PAGE
} from "./types";
import { setLoading } from "./commonActions";

const setCurrentPage = page => {
  return {
    type: SET_CURRENT_PAGE,
    payload: page
  };
};

export const setFilter = filter => {
  return {
    type: SET_JOB_SEARCH_FILTER,
    payload: filter
  };
};

const setSearchResult = result => {
  return {
    type: SET_JOB_SEARCH_RESULT,
    payload: result
  };
};

export const incrementViewCount = (id, userId = null) => dispatch => {
  client
    .patch("job/" + id + "/viewCount", { userId: userId })
    .then(response => {
      //Do nothing
    })
    .catch(error => {
      console.log(error);
      // Alert.error(errorToStr(error));
    });
};

export const addNewJob = (fd, verified = false) => dispatch => {
  dispatch(setLoading());
  client
    .post("job", fd, {
      headers: { "content-type": "multipart/form-data" }
    })
    .then(res => {
      if (verified) {
        Alert.success("新しい求人広告を追加しました。");
        client
          .post(`job/${res.data._id}/tweet`)
          .then(response => {
            console.log("Tweeted");
          })
          .catch(error => {
            console.log(error);
            console.log("Tweet failed");
          });

        client
          .post(`job/${res.data._id}/fbpost`)
          .then(response => {
            console.log("FB Posted");
          })
          .catch(error => {
            console.log(error);
            console.log("Post failed");
          });
      } else {
        Alert.success(
          "新しい求人広告を保存しました。<br/>企業アカウントが承認されると自動的に表示されるようになりますので、しばらくお待ちください。"
        );
      }
      history.push("/mypage/postedjoblist");
    })
    .catch(error => {
      Alert.error(errorToStr(error));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const updateJob = (id, fd, tweet = false) => dispatch => {
  dispatch(setLoading());
  client
    .patch("job/" + id, fd, {
      headers: { "content-type": "multipart/form-data" }
    })
    .then(response => {
      Alert.success("求人広告を更新しました");
      if (tweet) {
        client
          .post(`job/${id}/tweet`)
          .then(response => {
            console.log("Tweeted");
          })
          .catch(error => {
            console.log(error);
            console.log("Tweet failed");
          });
      }
      history.push("/mypage/postedjoblist");
    })
    .catch(error => {
      Alert.error(errorToStr(error));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const changePage = page => dispatch => {
  dispatch(setCurrentPage(page));
};

export const startSearch = (filter, page, size) => dispatch => {
  dispatch(setLoading());
  // dispatch(setFilter(filter));

  let params = {
    page: page,
    size: size
  };

  client
    .post("job/search", filter, { params: params })
    .then(response => {
      dispatch(setSearchResult(response.data));
    })
    .catch(error => {
      Alert.error(errorToStr(error));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
