import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../../history';
import BusinessProfileEdit from './BusinessProfileEdit';
import PersonalProfileEdit from './PersonalProfileEdit';
import Spinner from '../../common/Spinner';

const ProfileEdit = () => {
    const user = useSelector(state => state.user.currentUser);
    const loading = useSelector(state => state.common.loading);

    if (!user) {
        history.push('/signup');
        return null;
    }

    return (
        <div className="container">
            <h1 className="heading u-margin-medium">{user.profile.user_type === "Business" ? "企業紹介" : "プロフィール"}編集</h1>
            <div>
                {
                    user.profile.user_type === "Business" ? <BusinessProfileEdit /> : <PersonalProfileEdit />
                }
                {
                    loading ? <Spinner cover={true} /> : null
                }
            </div>
        </div>
    )
}

export default ProfileEdit
