import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../history';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../actions/authActions';
import { getNotifications } from '../actions/userActions';
import { getAreaList, getCandidateCount } from '../actions/commonActions';
import '../css/style.css';
import 'animate.css/animate.min.css';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';

import Header from './layout/Header';
import Footer from './layout/Footer';

import Home from './home/Home';
import JobSearch from './job/JobSearch';
import JobDetail from './job/JobDetail';
import GlobalNav from './layout/GlobalNav';
import Signup from './account/Signup';
import Notifications from './account/Notifications';
import VerifyEmail from './auth/VerifyEmail';
import ForgotPassword from './auth/ForgotPassword';
import ResetPassword from './auth/ResetPassword';
import Signin from './account/Signin';
import PostedJob from './account/MyPage/PostedJob';
import JobEdit from './job/JobEdit';
import SavedJob from './account/MyPage/SavedJob';
import ApplyJob from './job/ApplyJob';
import EmailSent from './static/EmailSent';
import NotFound from './static/NotFound';
import Terms from './static/Terms';
import Privacy from './static/Privacy';
import ContactForm from './contact/ContactForm';
import BusinessProfileList from './profile/BusinessProfileList';
import PersonalProfileList from './profile/PersonalProfileList';
import Dashboard from './admin/Dashboard';
import ThreadHome from './thread/ThreadHome';
import ThreadEditForm from './thread/ThreadEditForm';
import ThreadDetail from './thread/ThreadDetail';
import MailHome from './common/MailHome';
import BusinessProfileDetail from './profile/BusinessProfileDetail';
import AccountEdit from './account/MyPage/AccountEdit';
import ProfileEdit from './account/MyPage/ProfileEdit';
import Alert from '../utils/alert';
import Help from './help/Help';
import ScrollToTop from './common/ScrollToTop';

function alertForIE() {
  let userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
    Alert.success(`当サイトは Internet Explorer 上でも動作はしますが、完全にはサポートしておりません。
      <br>Internet Explorer 自体が古いブラウザでセキュリティに問題が有る為、<b>最新のモダンブラウザ（Microsoft Edge や Firefox、Google Chrome）</b>の使用をご検討ください。</p>
      <br><a href="https://www.google.co.jp/chrome/index.html" style="color:blue">Google Chromeをダウンロード</a>`);
  }
}

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAreaList());
    dispatch(getCurrentUser());
    dispatch(getNotifications());
    dispatch(getCandidateCount());
    alertForIE();
  }, [dispatch])

  return (
    <div className="App">
      <Router history={history}>
        <div className="fixedArea">
          <Header />
          <GlobalNav />
        </div>
        <div className="mainArea">
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/jobs" exact component={JobSearch} />
            <Route path="/profiles" exact component={BusinessProfileList} />
            <Route path="/profiles/:id" exact component={BusinessProfileDetail} />
            <PrivateRoute path="/personalprofiles" userType="Business" exact component={PersonalProfileList} />
            <PrivateRoute path="/jobs/edit" userType="Business" exact component={JobEdit} />
            <PrivateRoute path="/jobs/edit/:id" userType="Business" exact component={JobEdit} />
            <Route path="/jobs/:id" exact component={JobDetail} />
            <PrivateRoute path="/jobs/:id/apply" userType="Personal" exact component={ApplyJob} />
            <PrivateRoute path="/mail" exact component={MailHome} />
            <Route path="/thread/edit" exact component={ThreadEditForm} />
            <Route path="/thread/edit/:id" exact component={ThreadEditForm} />
            <Route path="/thread/:id" exact component={ThreadDetail} />
            <Route path="/thread" exact component={ThreadHome} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/signin" exact component={Signin} />
            <PrivateRoute path="/mypage/account" exact component={AccountEdit} />
            <PrivateRoute path="/mypage/profile" exact component={ProfileEdit} />
            <PrivateRoute path="/notifications" exact component={Notifications} />
            <PrivateRoute path="/mypage/postedjoblist" userType="Business" exact component={PostedJob} />
            <PrivateRoute path="/mypage/savedjoblist" userType="Personal" exact component={SavedJob} />
            <Route path="/auth/verifyemail/:token" exact component={VerifyEmail} />
            <Route path="/auth/forgotpassword" exact component={ForgotPassword} />
            <Route path="/auth/resetpassword/:token" exact component={ResetPassword} />
            <Route path="/contact" exact component={ContactForm} />
            <Route path="/emailsent" exact component={EmailSent} />
            <Route path="/privacy" exact component={Privacy} />
            <Route path="/terms" exact component={Terms} />
            <Route path="/help" exact component={Help} />
            <AdminRoute path='/admin' exact component={Dashboard} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
