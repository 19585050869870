import React from 'react'
import Icon from '../common/Icon';

const TwitterPage = () => {
    return (
        <a href="https://twitter.com/nzworks_jp" className="twitterPage">
            <Icon iconClassName="fab fa-lg fa-twitter" /><span className="u-margin-left-small"> Follow @nzworks_jp</span>
        </a>
    )
}

export default TwitterPage

