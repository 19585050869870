import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import formatDate from "../../utils/formatDate";
import client from "../../utils/client";
import Alert from "../../utils/alert";
import errorToStr from "../../utils/errorToStr";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { getJobList } from "../../actions/adminActions";

const DEFAULT_PAGE_SIZE = 50;

const AdminJobList = props => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [filter, setFilter] = useState({ unverified: false });

  const dispatch = useDispatch();
  const jobList = useSelector(state => state.admin.jobList);
  const itemCount = useSelector(state => state.admin.jobCount);

  useEffect(() => {
    setLoading(true);
    dispatch(getJobList(currentPage, DEFAULT_PAGE_SIZE, filter));
  }, [currentPage, filter, setLoading, getJobList, dispatch]);

  useEffect(() => {
    setLoading(false);
  }, [jobList, setLoading]);

  const handlePageClick = selectedPage => {
    setPage(selectedPage);
  };

  const handleVerify = id => {
    Alert.confirm("Verify？").then(result => {
      if (result.value) {
        setLoading(true);
        client
          .patch("admin/jobs/" + id + "/verify")
          .then(response => {
            Alert.success("Verify しました");
            dispatch(getJobList(currentPage, DEFAULT_PAGE_SIZE, filter));
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const renderJobList = () => {
    if (jobList.length === 0)
      return (
        <tr>
          <td>No data</td>
        </tr>
      );

    return jobList.map(job => {
      return (
        <tr key={job._id}>
          <td>
            <button
              className="button is-small"
              onClick={() => handleVerify(job._id)}
              disabled={job.verified ? "disabled" : null}
            >
              Verify
            </button>
          </td>
          <th>{job.title}</th>
          <td>{job.user.name}</td>
          <td>{job.viewCount}</td>
          <td>{job.address}</td>
          <td>{job.location ? "OK" : "N/A"}</td>
          <td>{formatDate(job.created_at)}</td>
          <td>{formatDate(job.updated_at)}</td>
          <td>{formatDate(job.tweetedAt)}</td>
        </tr>
      );
    });
  };

  return (
    <div className={props.className}>
      <div className="adminJobList">
        {loading ? (
          <Spinner />
        ) : (
          <table className="adminTable">
            <tbody>{renderJobList()}</tbody>
          </table>
        )}
      </div>
      <Pagination
        itemCount={itemCount}
        pageSize={DEFAULT_PAGE_SIZE}
        onPageChange={handlePageClick}
        className="bg-secondary-light"
      />
    </div>
  );
};

export default AdminJobList;
