import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { signUp } from "../../actions/authActions";
import useForm, { FormContext } from "react-hook-form";

import TextField from "../form/TextField";
import GoogleLogin from "../auth/GoogleLogin";
import Tooltip from "../common/Tooltip";
import Icon from "../common/Icon";

function Signup(props) {
  const dispatch = useDispatch();
  const methods = useForm(); // initialise the hook
  const [showPassword, setShowPassword] = useState(false);
  const { register, setValue, errors, handleSubmit } = methods;
  let userType = methods.watch("user_type");

  useEffect(() => {
    register({ name: "user_type" }, { required: true });
  }, [register]);

  const handleUserTypeChange = userType => {
    setValue("user_type", userType);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = data => {
    let fd = Object.assign({}, data);
    fd.profile = { user_type: fd.user_type };
    fd.password2 = undefined;
    fd.user_type = undefined;
    dispatch(signUp(fd));
  };

  return (
    <div className="section">
      <h1 className="heading">アカウント作成</h1>
      <div className="userForm">
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label">
                <span className="required">
                  アカウントタイプを選んでください
                </span>
                <Tooltip
                  className={"u-margin-left-small"}
                  iconClass={"fas fa-question-circle has-text-warning"}
                >
                  <p>
                    仕事を探している方は『求職者』,
                    <br />
                    求人広告を投稿したい方は『企業・雇用主』を選んでください
                  </p>
                </Tooltip>
              </label>
              <div className="tabs is-toggle control">
                <ul>
                  <li
                    className={userType === "Personal" ? "is-active" : ""}
                    onClick={() => handleUserTypeChange("Personal")}
                  >
                    <a href="# ">
                      <span>求職者</span>
                    </a>
                  </li>
                  <li
                    className={userType === "Business" ? "is-active" : ""}
                    onClick={() => handleUserTypeChange("Business")}
                  >
                    <a href="# ">
                      <span>企業・雇用主</span>
                    </a>
                  </li>
                </ul>
              </div>
              {errors["user_type"] && (
                <p className="help is-danger">
                  アカウントタイプを選んでください
                </p>
              )}
            </div>
            <TextField
              label={
                userType === "Business"
                  ? "表示名（会社名、雇用者名等）"
                  : "表示名"
              }
              type="text"
              placeholder="サイト内で使用する名前を入力してください"
              name="name"
              registerOptions={{ required: true, maxLength: 100 }}
            />
            <TextField
              label="Email"
              type="email"
              placeholder="Ｅメールアドレスを入力してください"
              name="email"
              info="※アカウント認証確認メールを送信する為、必ず実際に使えるメールアドレスをご登録ください"
              registerOptions={{ required: true, maxLength: 100 }}
            />
            <div className="u-flex u-flex-align">
              <div className="u-flex-grow">
                <TextField
                  label="パスワード"
                  type={showPassword ? "text" : "password"}
                  placeholder="パスワードを入力してください"
                  name="password"
                  registerOptions={{
                    required: true,
                    minLength: 6,
                    maxLength: 50
                  }}
                />
                <TextField
                  label="パスワード確認"
                  type={showPassword ? "text" : "password"}
                  placeholder="パスワードをもう一度入力してください"
                  name="password2"
                  customErrorMessage="パスワードが一致しません"
                  registerOptions={{
                    required: true,
                    validate: value => {
                      return value === methods.watch("password");
                    }
                  }}
                />
              </div>
              <div className="u-margin-small">
                <Icon
                  onClick={togglePassword}
                  iconClassName={`fas ${
                    showPassword ? "fa-eye-slash" : "fa-eye"
                  } fa-2x`}
                  className="has-text-info is-medium u-margin-top-medium"
                />
              </div>
            </div>

            <div className="field u-margin-top-small">
              <div className="control">
                <button className="button is-link">登録</button>
              </div>
            </div>
          </form>
        </FormContext>
        <hr />
        <h4>もしくは</h4>
        <GoogleLogin userType={userType} />
      </div>
    </div>
  );
}

export default Signup;
