import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Alert from "../../../utils/alert";
import errorToStr from "../../../utils/errorToStr";
import client from "../../../utils/client";
import Spinner from "../../common/Spinner";
import PostedJobList from "./PostedJob/PostedJobList";

const PostedJob = props => {
  const user = useSelector(state => state.user.currentUser);

  const [postedJobList, setPostedJobList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;

    setLoading(true);

    client
      .get("user/jobs")
      .then(response => {
        setPostedJobList(response.data);
      })
      .catch(error => {
        Alert.error(errorToStr(error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user]);

  const handleDeleteJob = id => {
    Alert.confirm("この広告を削除しますか？").then(result => {
      if (result.value) {
        // dispatch(deleteJob(id));
        setLoading(true);
        client
          .delete("job/" + id)
          .then(response => {
            Alert.success("求人広告を削除しました");
            setPostedJobList(
              postedJobList.filter(postedJob => postedJob._id !== id)
            );
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleActivateJob = id => {
    Alert.confirm("募集を再開しますか？").then(result => {
      if (result.value) {
        // dispatch(activateJob(id));
        setLoading(true);
        client
          .patch("job/" + id, { is_active: true })
          .then(response => {
            Alert.success("募集を再開しました");
            _changeJobStatus(id, true);
            // history.push('/mypage/postedjoblist');
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleDeactivateJob = id => {
    Alert.confirm("募集を停止しますか？").then(result => {
      if (result.value) {
        // dispatch(deactivateJob(id));
        setLoading(true);
        client
          .patch("job/" + id, { is_active: false })
          .then(response => {
            Alert.success("募集を停止しました");
            _changeJobStatus(id, false);
            // history.push('/mypage/postedjoblist');
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handlwTweetJob = id => {
    Alert.confirm(
      '<p style="text-align: left">ニュージーワークス専用ツイッター及び Facebook 上でこの求人情報を再告知します。<br/>よろしいですか？</p>',
      { width: "40rem" }
    ).then(result => {
      if (result.value) {
        setLoading(true);
        client
          .post(`job/${id}/tweet`)
          .then(res2 => {
            Alert.success("シェアしました");
            _changeTweetDate(id);
          })
          .catch(error => {
            console.log(error);
            console.log("Tweet failed");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const _changeJobStatus = (id, status) => {
    let temp = [...postedJobList];
    let index = temp.findIndex(job => job._id === id);
    temp[index].is_active = status;
    setPostedJobList(temp);
  };

  const _changeTweetDate = id => {
    let temp = [...postedJobList];
    let index = temp.findIndex(job => job._id === id);
    temp[index].tweetedAt = Date.now;
    setPostedJobList(temp);
  };

  const JOB_CREATION_LIMIT = 10;

  return (
    <div className="container">
      <h1 className="heading u-margin-medium">求人広告リスト</h1>
      {postedJobList.length >= JOB_CREATION_LIMIT ? (
        <div className="control has-text-centered">
          <button
            to="/jobs/edit"
            className="button is-primary is-medium"
            disabled
          >
            <span className="icon">
              <i className="fas fa-plus"></i>
            </span>
            <span>求人広告を作成する</span>
          </button>
          <p className="has-text-danger">
            ※1つのアカウントで作成できる広告は{JOB_CREATION_LIMIT}件までです
          </p>
        </div>
      ) : (
        <div className="control has-text-centered">
          <Link to="/jobs/edit" className="button is-primary is-medium">
            <span className="icon">
              <i className="fas fa-plus"></i>
            </span>
            <span>求人広告を作成する</span>
          </Link>
        </div>
      )}
      {!user.profile.companyVerified && postedJobList.length > 1 && (
        <div className="u-margin-medium">
          <div className="warningBox">
            <p className="has-text-danger">
              現在アカウントが未承認である為、求人広告は検索結果に表示されません。
            </p>
            <p>
              アカウントが承認されるまでしばらくお待ちください。（ほとんどの場合
              １～２ 日程で承認されます）
            </p>
          </div>
        </div>
      )}
      {loading ? (
        <Spinner />
      ) : (
        <PostedJobList
          onActivate={handleActivateJob}
          onDeactivate={handleDeactivateJob}
          onDelete={handleDeleteJob}
          onTweet={handlwTweetJob}
          jobList={postedJobList}
          companyVerified={user.profile.companyVerified}
        />
      )}
    </div>
  );
};

export default PostedJob;
