import {
    MAIL_SET_RECORD_INFO,
    MAIL_SET_SENDING,
    MAIL_SET_ERRORS
} from '../actions/types';

const INITIAL_STATE = {
    recordInfo: null,
    seiding: false,
    errors: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAIL_SET_RECORD_INFO:
            return {
                ...state,
                recordInfo: action.payload
            }
        case MAIL_SET_SENDING:
            return {
                ...state,
                sending: action.payload
            }
        case MAIL_SET_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state;
    }
}
