import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../../actions/authActions';
import history from '../../history';
import client from '../../utils/client';
import Alert from '../../utils/alert';
import errorToStr from '../../utils/errorToStr';

function VerifyEmail(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        let token = props.match.params.token;
        if (!token) {
            console.log('No token');
            history.push('/')
        }
        else {
            client.get('auth/verify', { params: { token: token } })
                .then(res => {
                    setTimeout(() => {
                        Alert.success("アカウントを認証しました。<br/><br/>登録したメールアドレス、パスワードを使用してログインしてください。");
                        history.push('/signin');
                        // dispatch(setCurrentUser(res.data));
                    }, 1500)
                })
                .catch(error => {
                    Alert.error(errorToStr(error));
                    history.push('/');
                })
        }
    }, [props, dispatch])


    return (
        <section className="section">
            <div className="container">
                <h5 className="has-text-centered">アカウントを認証しています</h5>
            </div>
        </section>
    )
}

export default VerifyEmail;