import React, { useState } from 'react';
import HelpPanel from './HelpPanel';
import HelpRegister from './pages/HelpRegister';
import HelpPersonalProfile from './pages/HelpPersonalProfile';

const HelpPersonal = () => {
    const [accountOpen, setAccountOpen] = useState(false);
    const [profileEditOpen, setProfileEditOpen] = useState(false);


    return (
        <div>
            <h2 className="heading--label-primary">求職者用アカウント</h2>
            <div onClick={() => setAccountOpen(!accountOpen)} className="u-margin-medium">
                <HelpPanel title="アカウント登録" show={accountOpen} >
                    <HelpRegister />
                </HelpPanel>
            </div>
            <div onClick={() => setProfileEditOpen(!profileEditOpen)} className="u-margin-medium">
                <HelpPanel title="プロフィール編集" show={profileEditOpen} >
                    <div className="info">
                        <p>
                            紹介文を記入して公開状態に設定しておくと、企業・雇用主ユーザーが紹介文を閲覧できるようになり、且つニュージーワークス経由でメールを送れるようになります。
                        </p>
                        <p>（プロフィールを設定しなくても求人検索・応募は可能です。）</p>
                    </div>
                    <HelpPersonalProfile />
                </HelpPanel>
            </div>
        </div>
    )
}

export default HelpPersonal
