import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signIn } from "../../actions/authActions";
import useForm, { FormContext } from "react-hook-form";

import TextField from "../form/TextField";
import GoogleLogin from "../auth/GoogleLogin";
import Icon from "../common/Icon";

function Signin(props) {
  const dispatch = useDispatch();
  const methods = useForm(); // initialise the hook
  const [showPassword, setShowPassword] = useState(false);
  const { register, setValue, errors, handleSubmit } = methods;
  let userType = methods.watch("user_type");

  useEffect(() => {
    register({ name: "user_type" }, { required: true });
  }, [register]);

  const handleUserTypeChange = userType => {
    setValue("user_type", userType);
  };

  const onSubmit = data => {
    dispatch(signIn(data.email, data.password));
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="section">
      <h1 className="heading">ログイン</h1>
      <div className="userForm">
        <div className="field">
          <label className="label required">アカウントタイプ</label>
          <div className="tabs is-toggle control">
            <ul>
              <li
                className={userType === "Personal" ? "is-active" : ""}
                onClick={() => handleUserTypeChange("Personal")}
              >
                <a href="# ">
                  <span>求職者</span>
                </a>
              </li>
              <li
                className={userType === "Business" ? "is-active" : ""}
                onClick={() => handleUserTypeChange("Business")}
              >
                <a href="# ">
                  <span>企業・雇用主</span>
                </a>
              </li>
            </ul>
          </div>
          {errors["user_type"] && (
            <p className="help is-danger">アカウントタイプを選んでください</p>
          )}
        </div>
        <FormContext {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Email"
              type="email"
              placeholder="Ｅメールアドレスを入力してください"
              name="email"
              registerOptions={{ required: true, maxLength: 100 }}
            />
            <div className="u-flex u-flex-align">
              <div className="u-flex-grow">
                <TextField
                  label="パスワード"
                  type={showPassword ? "text" : "password"}
                  placeholder="パスワードを入力してください"
                  name="password"
                  registerOptions={{
                    required: true,
                    minLength: 6,
                    maxLength: 50
                  }}
                />
              </div>
              <div className="u-margin-medium">
                <Icon
                  onClick={togglePassword}
                  iconClassName={`fas ${
                    showPassword ? "fa-eye-slash" : "fa-eye"
                  } fa-2x`}
                  className="has-text-info is-medium u-margin-top-medium"
                />
              </div>
            </div>
            <div className="field u-margin-top-small">
              <div className="u-flex u-space-between">
                <div>
                  <Link className="link" to="/auth/forgotpassword">
                    パスワードを忘れた方
                  </Link>
                  <br />
                  <Link className="link" to="/signup">
                    初めてご利用の方（新規登録）
                  </Link>
                </div>
                <button className="button is-inline is-link">ログイン</button>
              </div>
            </div>
          </form>
        </FormContext>
        <hr />
        <h4>もしくは</h4>
        <GoogleLogin userType={userType} />
      </div>
    </div>
  );
}

export default Signin;
