import React from 'react';
import srcSelectMenu from '../../../img/help/help_select_menu.jpg';
import srcBusinessJobMenu from '../../../img/help/help_business_job_menu.jpg';
import srcBusinessJobUpdate from '../../../img/help/help_business_job_update.jpg';

const HelpBusinessJobUpdate = () => {
    return (
        <div>
            <div className="line">・画面右上、メニューをクリックまたはタップします</div>
            <div className="image">
                <img src={srcSelectMenu} />
            </div>
            <hr />
            <div className="line">・メニューから『求人投稿・編集』を選択し、編集ページへ移動します。</div>
            <div className="image">
                <img src={srcBusinessJobMenu} />
            </div>
            <hr />
            <div className="line">『この求人を再告知する』ボタンをクリックします。</div>
            <div className="image">
                <img src={srcBusinessJobUpdate} />
            </div>
            <div className="line">その後確認画面が表示された時に『OK』ボタンを押すと、求人広告の更新日が変更され、<a href="https://twitter.com/nzworks_jp">NZワークス専用ツイッター</a>及び <a href="https://www.facebook.com/nzworksjp/">Facebook</a> にも自動的に情報が投稿されます。</div>
            <br />
            <div className="line has-text-info">※再告知ボタンは一度使用すると２週間の間使えなくなります。</div>
        </div>
    )
}

export default HelpBusinessJobUpdate
