import React, { Fragment } from 'react';
import { getJpegImageUrl, getResizedImageUrl } from '../../utils/imageManager';

const Image = ({ className, src, alt, size }) => {
    return (
        <Fragment >
            {
                size ? <img className={className} src={getJpegImageUrl(getResizedImageUrl(src, `c_scale,w_${size}`))} alt={alt} />
                    : <img className={className} src={getJpegImageUrl(src)} alt={alt} />
            }
        </Fragment>
    )

}

export default Image; 
