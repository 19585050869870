import React, { useEffect, useState, Fragment } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { snedMail } from '../../actions/mailActions';

import history from '../../history';
import MailForm from './MailForm';
import MailPreview from './MailPreview';
import useModal from '../hooks/useModal';
import Alert from '../../utils/alert';
import Spinner from '../common/Spinner';

const modalStyle = {
    maxWidth: '60rem',
    height: '50rem',
    padding: '2rem',
    margin: 'auto'
}

const MailHome = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(null);
    const [openModal, closeModal, modalIsOpen] = useModal();

    const recordInfo = useSelector(state => state.mail.recordInfo);
    const sending = useSelector(state => state.mail.sending);
    const errors = useSelector(state => state.mail.errors);
    const user = useSelector(state => state.user.currentUser);

    useEffect(() => {
        if (errors) {
            console.log(errors);
            history.push('/');
            Alert.error(errors);
        }
    }, [errors])

    const onSubmit = (data) => {
        setFormData(data);
        openModal();
    }

    const sendEmail = () => {
        //dispatch
        dispatch(snedMail(recordInfo.type, recordInfo.id, formData, () => {
            if (recordInfo.type === 'user') {
                history.push('personalprofiles')
            }
            else {
                history.push(`${recordInfo.type}/${recordInfo.id}`)
            }
        }));
    }

    const renderRecordInfo = () => {
        switch (recordInfo.type) {
            case "thread":
                return renderThreadRecordInfo();
            case "user":
                return renderUserRecordInfo();
            default:
                return null;
        }
    }

    const renderUserRecordInfo = () => {
        return (
            <Fragment>
                <p className="has-text-info u-margin-bottom-small">以下のユーザーにメールを送信します</p>
                <div className="has-background-light u-margin-bottom-medium u-padding-small">
                    <h3 className="u-margin-bottom-small">{recordInfo.userName}</h3>
                    <div className="u-text-wrap" style={{ maxHeight: '30rem', overflowY: 'auto' }}>
                        {recordInfo.details}
                    </div>
                </div>
            </Fragment>
        )
    }

    const renderThreadRecordInfo = () => {
        return (
            <Fragment>
                <p className="has-text-info u-margin-bottom-small">以下の投稿について問い合わせをします</p>
                <div className="has-background-light u-margin-bottom-medium u-padding-small">
                    <h3 className="u-margin-bottom-small">{recordInfo.title}</h3>
                    <div>
                        {recordInfo.details}
                    </div>
                    <hr />
                    <div>投稿者: {recordInfo.userName}</div>
                </div>
            </Fragment>
        )
    }

    return (
        <div className="container">
            <h1 className="heading u-margin-medium">メールを送る</h1>
            {renderRecordInfo()}
            <h2>メールフォーム</h2>
            <MailForm onSubmit={onSubmit} name={user ? user.name : ''} email={user ? user.email : ''} title={`${recordInfo.title}`} />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className='modalContent'
                style={{ overlay: { zIndex: 1000 }, content: modalStyle }}
            >
                <div>以下の内容でメールを送信します。よろしいですか？</div>
                <hr />
                <MailPreview formData={formData} />
                <hr />
                {
                    sending ? <Spinner /> : (
                        <div className="field is-grouped">
                            <div className="control">
                                <button type="submit" onClick={sendEmail} className="button is-link">送信</button>
                            </div>
                            <div className="control">
                                <button type="button" className="button is-link is-light" onClick={closeModal}>キャンセル</button>
                            </div>
                        </div>
                    )
                }
            </Modal>
        </div>
    )
}

export default MailHome
