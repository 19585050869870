import React from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from './FieldError';

const SwitchField = ({
    name,
    className,
    onChange,
    label,
    info,
    registerOptions={}
}) => {
    const { register, errors } = useFormContext();

    return (
        <div className={`switchField ${className}`}>
            <label className="label">{label}</label>
            <div className="u-flex u-flex-align">
                <div className="u-margin-right-small">OFF</div>
                <div className="switch u-margin-small">
                    <input 
                        id={`${name}_switch`}
                        name={name}
                        type="checkbox" 
                        onChange={onChange} 
                        ref={register(registerOptions)}
                    ></input>
                    <label htmlFor={`${name}_switch`} />
                </div>
                <div className="u-margin-left-small">ON</div>
            </div>
            {info && <p className="help is-success">{info}</p>}
            <FieldError errors={errors[name]} />
        </div>
    )
}

export default SwitchField
