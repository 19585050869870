import React from 'react'

export default function NotFound() {
    return (
        <div className="container">
        <h1 className="display-4">Page Not Found</h1>
        <p>ページが見つかりません</p>
    </div>
    )
}
