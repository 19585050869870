import React, { useEffect } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addThread, getThread, editThread } from '../../actions/threadActions';

import { resizeFile } from '../../utils/imageManager';

import TextField from '../form/TextField';
import Spinner from '../common/Spinner';
import MainImageSelector from '../form/MainImageSelector';

const ThreadEditForm = (props) => {
    const threadId = props.match.params.id;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.common.loading);
    const selectedThread = useSelector(state => state.thread.selectedThread);
    const methods = useForm(); // initialise the hook
    const { register, setValue, handleSubmit } = methods;

    useEffect(() => {
        register({ name: 'mainImage' });

        if (threadId) {
            dispatch(getThread(threadId));
        }
    }, [register, threadId, dispatch])

    useEffect(() => {
        if (threadId && selectedThread) {
            setValue("title", selectedThread.title);
            setValue("details", selectedThread.details);
        }
    }, [threadId, selectedThread, setValue])

    const onSubmit = async data => {
        if (threadId) {
            dispatch(editThread(threadId, data));
        }
        else {
            let fd = new FormData();
            if (data.mainImage) {
                //Resize image here
                let resizedFile = await resizeFile(data.mainImage, 1024, data.mainImage.name);
                fd.append('photo', resizedFile, resizedFile.name);
            }
            fd.append('title', data.title);
            fd.append('details', data.details);
            if (data.email) {
                fd.append('email', data.email);
            }

            dispatch(addThread(fd));
        }
    }

    const handleFileChange = file => {
        setValue('mainImage', file);
    }

    return (
        <div className="container" style={{ maxWidth: '80rem', margin: 'auto', padding: '5rem 1rem' }}>
            <h1 className="heading u-margin-bottom-medium">新規投稿</h1>
            <FormContext {...methods} >
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        label="タイトル"
                        type="title"
                        className="field"
                        placeholder="タイトルを入力してください"
                        name="title"
                        registerOptions={{ required: true, maxLength: 100 }}
                    />
                    <TextField
                        label="詳細"
                        type="textarea"
                        className="field"
                        name="details"
                        rows={15}
                        registerOptions={{ required: true, minLength: 6, maxLength: 5000 }}
                    />
                    <TextField
                        label="連絡先メールアドレス"
                        type="email"
                        className="field"
                        placeholder="メールアドレスを入力してください"
                        name="email"
                        registerOptions={{ maxLength: 100 }}
                    />
                    <p className="has-text-success">メールアドレスを設定すると他のユーザーがNZワークス経由で連絡する事が可能になります。</p>
                    <p className="has-text-success u-margin-bottom-small">（メールアドレス自体が公開される事はありません）</p>
                    {
                        threadId ? null : (
                            <div className="field">
                                <label className="label">メイン画像</label>
                                <MainImageSelector onFileChange={handleFileChange} />
                            </div>
                        )
                    }

                    <div className="field u-margin-medium ">
                        <div className="has-text-centered">
                            <button className="button is-inline is-link is-large" disabled={loading ? 'disabled' : ''}>{threadId ? '更新' : '投稿する'}</button>
                        </div>
                    </div>
                </form>
            </FormContext>
            {loading ? <Spinner cover={true} /> : null}
        </div>
    )
}

export default ThreadEditForm
