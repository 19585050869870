import React, { useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import { getUserList, getLatestUserList } from "../../actions/profileActions";

import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
// import Icon from '../common/Icon';
import BusinessProfileCard from "./BusinessProfileCard";
// import BusinessProfileDetail from './BusinessProfileDetail';
import BusinessProfileCardMin from "./BusinessProfileCardMin";

import AdNZWorksLarge from "../common/ads/AdNZWorksLarge";
import AdNZCafemap from "../common/ads/AdNZCafemap";
import { getNewNotificationNum } from "../../utils/getNotificationNum";

Modal.setAppElement("#root");

const DEFAULT_PAGE_SIZE = 24;

const BusinessProfileList = () => {
  const profileList = useSelector(state => state.profile.profileList);
  const latestProfileList = useSelector(
    state => state.profile.latestProfileList
  );
  const itemCount = useSelector(state => state.profile.itemCount);
  const loading = useSelector(state => state.common.loading);
  const currentUser = useSelector(state => state.user.currentUser);
  const notifications = useSelector(state => state.user.notifications);
  const userType = currentUser ? currentUser.profile.user_type : null;
  let notificationNum = getNewNotificationNum(notifications);

  const adCount = 3 - (profileList.length % 3);

  // const [result, setResult] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [selectedUser, setUser] = useState(null);
  // const [modalIsOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  // const closeModal = () => {
  //     setModalOpen(false);
  // }

  useEffect(() => {
    dispatch(getUserList(1, DEFAULT_PAGE_SIZE));
    dispatch(getLatestUserList());
    // setUser(null);
    // setModalOpen(false);
  }, [dispatch]);

  // useEffect(() => {
  //     if (selectedUser) {
  //         setModalOpen(true);
  //     }
  //     else {
  //         setModalOpen(false);
  //     }
  // }, [selectedUser, setModalOpen])

  const renderAds = () => {
    switch (adCount) {
      case 2:
        return (
          <Fragment>
            <div className="u-margin-small businessProfileCard">
              <AdNZWorksLarge />
            </div>
            <div className="u-margin-small businessProfileCard">
              <AdNZCafemap />
            </div>
          </Fragment>
        );
      case 1:
        return (
          <div className="u-margin-small businessProfileCard">
            <AdNZWorksLarge />
          </div>
        );
      default:
        return null;
    }
  };

  const handlePageClick = selectedPage => {
    // dispatch(changePage(selectedPage));
    dispatch(getUserList(selectedPage, DEFAULT_PAGE_SIZE));
  };

  // const handleCardClick = user => {
  //     setUser(user);
  // }

  const renderProfileList = () => {
    if (!profileList) return null;
    // let dummyCount = 3 - (profileList.length % 3);

    return profileList.map(user => {
      return (
        // <div key={user._id} className='u-margin-small hover-basic' onClick={() => handleCardClick(user)}>
        <div key={user._id} className="u-margin-small">
          <Link to={`/profiles/${user._id}`}>
            <BusinessProfileCard user={user} />
          </Link>
        </div>
      );
    });
  };

  const renderLatestProfileList = () => {
    if (!latestProfileList) return null;

    return latestProfileList.map(user => {
      return (
        // <div key={user._id} className="u-margin-small" onClick={() => handleCardClick(user)}>
        <div key={user._id} className="u-margin-small">
          <Link to={`/profiles/${user._id}`}>
            <BusinessProfileCardMin user={user} />
          </Link>
        </div>
      );
    });
  };

  const renderHowTo = () => {
    return (
      <div className="warningBox">
        <p className="has-text-danger">
          <b>
            企業紹介一覧にアカウント情報を表示する為には、メイン画像の設定及び企業紹介文を記述する必要が有ります。
          </b>
        </p>
        <p>
          画面右上の『メニュー』から『企業紹介編集』を選択し、各変更を行ってください。
        </p>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          {notificationNum ? `(${notificationNum})` : ""}
          登録企業・雇用者プロフィール
        </title>
        <meta
          name="description"
          content="ニュージーワークスに登録されている企業・雇用者の一覧です。（画像と紹介文が設定されているアカウントのみ表示されます）"
        />
      </Helmet>
      <div className="pageHeader">
        <h1 className="heading">登録企業・雇用者プロフィール</h1>
      </div>
      <div className="businessProfileList container">
        {userType === "Business" ? (
          renderHowTo()
        ) : (
          <p className="has-text-danger u-margin-small">
            ※企業紹介文及びメイン画像が設定されているアカウントのみ表示されます
          </p>
        )}

        <div className="u-flex-responsive">
          <div style={{ flexGrow: 1 }}>
            <div
              className="u-flex u-flex-wrap u-flex-justify"
              style={{ minHeight: "80vh" }}
            >
              {loading ? (
                <Spinner cover={true} />
              ) : (
                renderProfileList(profileList)
              )}
            </div>
            <Pagination
              itemCount={itemCount}
              pageSize={DEFAULT_PAGE_SIZE}
              onPageChange={handlePageClick}
              className="bg-secondary-light"
            />
          </div>
          <div className="businessProfileList__side">
            <h3>最近更新があったアカウント</h3>
            <div>{renderLatestProfileList()}</div>
          </div>
        </div>
        {/* <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setUser(null)}
                    className='modalContent'
                    style={{ overlay: { zIndex: 1000 } }}
                >
                    {
                        selectedUser ? (
                            <div>
                                <BusinessProfileDetail userId={selectedUser._id} />
                                <div className="modalCloseButton">
                                    <Icon className="fas fa-3x" iconClassName="fas fa-times-circle" onClick={() => setUser(null)} />
                                </div>
                            </div>
                        )
                            : <p>Loading...</p>
                    }

                </Modal> */}
      </div>
    </div>
  );
};

export default BusinessProfileList;
