import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Icon from "../../../common/Icon";
import { TWEET_INTERVAL } from "../../../../constants";

const PostedJobList = props => {
  const { jobList, companyVerified = false } = props;

  const handleDeleteJob = id => {
    props.onDelete(id);
  };

  const handleActivateJob = id => {
    props.onActivate(id);
  };

  const handleDeactivateJob = id => {
    props.onDeactivate(id);
  };

  const handleTweet = id => {
    props.onTweet(id);
  };

  const renderTags = tags => {
    if (tags && tags.length > 0) {
      return tags.map(tag => {
        return (
          <span key={tag} className="tag is-primary is-light">
            {tag}
          </span>
        );
      });
    }
    return "未設定";
  };

  const renderJobTable = job => {
    return (
      <dl>
        <dt>エリア</dt>
        <dd>
          {job.area ? (
            <span className="tag is-primary">{job.area.name}</span>
          ) : (
            "未設定"
          )}
        </dd>
        <dt>職種</dt>
        <dd>
          <span className="tag is-success">{job.jobCategory}</span>
        </dd>
        <dt>詳細</dt>
        <dd>{job.details}</dd>
        <dt>勤務タイプ</dt>
        <dd>{job.workType ? job.workType : "不問"}</dd>
        <dt>必要英語レベル</dt>
        <dd>{job.englishLevel ? job.englishLevel : "不問"}</dd>
        <dt>タグ</dt>
        <dd>{renderTags(job.tags)}</dd>
      </dl>
    );
  };

  const enableTweet = job => {
    if (!job.is_active) return false;
    if (!job.tweetedAt) return true;

    let timeDiff = Date.now() - new Date(job.tweetedAt).getTime();
    return timeDiff > TWEET_INTERVAL ? true : false;
  };

  const shareButtonStatus = job => {
    let timeDiff = Date.now() - new Date(job.tweetedAt).getTime();
    if (timeDiff > TWEET_INTERVAL) return null;

    let waitDate = (TWEET_INTERVAL - timeDiff) / 86400000;
    return `あと${Math.round(waitDate) + 1}日で再告知可能`;
  };

  return (
    <div className="postedJobList">
      {!jobList || jobList.length === 0 ? (
        <p>データがありません</p>
      ) : (
        jobList.map(job => {
          return (
            <div
              className={`postedJobList__item ${
                job.is_active ? "" : "inactive"
              }`}
              key={job._id}
            >
              <div className="card">
                <header className="card-header">
                  <h3 className="card-header-title">{job.title}</h3>
                </header>
                <div className="viewCount">
                  <Icon iconClassName="fas fa-lg fa-eye" />
                  <span className="u-margin-left-small">x {job.viewCount}</span>
                </div>
                <div className="card-content">{renderJobTable(job)}</div>
              </div>
              <div className="buttons">
                <Link
                  to={`/jobs/edit/${job._id}`}
                  type="button"
                  className="button is-primary"
                >
                  編集
                </Link>
                {job.is_active ? (
                  <button
                    type="button"
                    onClick={() => handleDeactivateJob(job._id)}
                    className="button is-warning"
                  >
                    募集停止
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleActivateJob(job._id)}
                    className="button is-success"
                  >
                    募集再開
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => handleDeleteJob(job._id)}
                  className="button is-danger"
                >
                  削除
                </button>
              </div>
              {enableTweet(job) ? (
                companyVerified && (
                  <button
                    type="button"
                    className="button is-success u-margin-auto"
                    onClick={() => handleTweet(job._id)}
                  >
                    <div>
                      <Icon iconClassName="fab fa-lg fa-twitter" />
                      <Icon iconClassName="fab fa-lg fa-facebook" />
                      <span className="u-margin-left-small">
                        この求人を再告知する
                      </span>
                    </div>
                  </button>
                )
              ) : (
                <button
                  type="button"
                  className="button is-success u-margin-auto"
                  disabled
                >
                  <div>
                    <Icon iconClassName="fab fa-lg fa-twitter" />
                    <Icon iconClassName="fab fa-lg fa-facebook" />
                    <span className="u-margin-left-small text-danger">
                      {shareButtonStatus(job)}
                    </span>
                  </div>
                </button>
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

PostedJobList.propTypes = {
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
  onDelete: PropTypes.func,
  onTweet: PropTypes.func,
  companyVerified: PropTypes.bool
};

export default PostedJobList;
