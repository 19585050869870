import React from 'react';
import Image from '../common/Image';
import formatDate from '../../utils/formatDate';

export default function JobCard({ job, saved, applied }) {
    let jobDescription = job.details.length > 200 ? job.details.substr(0, 200) + '...' : job.details;
    let imageUrl = job.mainImage
        ? job.mainImage.image_url
        : (
            job.user.profile.avatar
                ? job.user.profile.avatar.image_url
                : null
        );

    const renderJobImage = () => {
        if (!imageUrl) return null;
        return (
            <div className="jobCard__image">
                <Image src={imageUrl} size={'200'} alt='Job image' />
            </div>
        )
    }

    const renderTags = tags => {
        if (!tags || tags.length === 0) return null;

        let tagContents = tags.map(tag => {
            return <span key={tag} className="tag is-primary is-light">{tag}</span>
        });

        return (
            <div className="tags u-margin-top-small">{tagContents}</div>
        )
    }

    const renderEnglishLevel = englishLevel => {
        let content = englishLevel ? englishLevel : '不問';
        return (
            <span className="tag is-warning is-light">
                英語力：{content}
            </span>
        )
    }

    return (
        <div className="jobCard hover-basic u-margin-bottom-small">
            <h3 className="jobCard__title">{job.title}</h3>
            <div className="jobCard__body">
                <div className="jobCard__left">
                    <div className="jobCard__user">
                        {renderJobImage()}
                        <div>
                            <h5 className="content has-text-primary">{job.area ? job.area.name : 'エリア指定無し'}</h5>
                            <p>投稿者:{job.user.name}</p>
                            <p><span className="icon"><i className="fas fa-calendar-alt"></i></span>{formatDate(job.updated_at)}</p>
                        </div>
                    </div>
                    <p className="tags">
                        <span className="tag is-warning">{job.jobCategory}</span>
                        {renderEnglishLevel(job.englishLevel)}
                        {
                            job.workType
                                ? <span className="tag is-success is-light">{job.workType}</span>
                                : null
                        }
                    </p>
                    {renderTags(job.tags)}
                </div>
                <div className="jobCard__description">{jobDescription}</div>
            </div>
        </div>
    )
}
