import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { startSearch, setFilter } from "../../actions/jobActions";
import MapSearch from "./JobSearch/MapSearch";
import SearchResult from "./JobSearch/SearchResult";
import JobFilter from "./JobFilter";
import AreaSelect from "../common/AreaSelect";
import FilterSummary from "./JobSearch/FilterSummary";
import PageLoading from "../common/PageLoading";
import { getNewNotificationNum } from "../../utils/getNotificationNum";

const DEFAULT_PAGE_SIZE = 20;

function JobSearch(props) {
  const filter = useSelector(state => state.job.searchFilter);
  const currentUser = useSelector(state => state.user.currentUser);
  const currentPage = useSelector(state => state.job.currentPage);
  const notifications = useSelector(state => state.user.notifications);

  let notificationNum = getNewNotificationNum(notifications);

  const dispatch = useDispatch();

  const [useMap, setUseMap] = useState(false);
  const [filterOpened, setFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(startSearch(filter, currentPage, DEFAULT_PAGE_SIZE));
    window.scrollTo(0, 0);
  }, [dispatch, filter, currentPage]);

  const handleAreaChange = areaId => {
    let newFilter = { ...filter };
    newFilter.area = areaId ? areaId : undefined;

    // setFilter(newFilter);
    dispatch(setFilter(newFilter));
  };

  const handleSaveFilter = savedFilter => {
    setFilterOpen(false);
    let newFilter = { ...filter };
    newFilter = Object.assign(newFilter, savedFilter);
    // setFilter(newFilter);
    dispatch(setFilter(newFilter));
  };

  const handleMapChange = data => {
    // console.log(data);
    console.log("Not impremented yet");
  };

  return (
    <div className="jobSearch">
      <Helmet>
        <title>{notificationNum ? `(${notificationNum})` : ""}求人検索</title>
        <meta
          name="description"
          content="ニュージーランドの求人情報をエリア、カテゴリ等様々な検索条件を指定して探す事ができます。"
        />
      </Helmet>

      <div className="jobSearch__fixedArea">
        <div className="jobSearch__fixedArea__container">
          <h1 className="jobSearch__fixedArea__header">求人検索</h1>
          <div className="u-margin-left-medium u-flex u-flex-align">
            <h4 className="is-hidden-mobile u-margin-small">エリア</h4>
            <AreaSelect
              onChange={handleAreaChange}
              emptyString="エリア指定無し"
              value={filter.area}
            />
          </div>

          <div className="u-margin-left-medium u-flex u-flex-align">
            <h4 className="is-hidden-mobile u-margin-small">フィルタ</h4>
            <button
              type="button"
              onClick={() => setFilterOpen(!filterOpened)}
              className="level-item button is-primary jobSearch__toggleFilter"
            >
              <span className="icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className={`jobSearch__filter${filterOpened ? " active" : ""}`}>
        <JobFilter onSaveFilter={filter => handleSaveFilter(filter)} />
      </div>
      <FilterSummary filter={filter} />
      {currentUser?.profile.user_type === "Business" && (
        <div className="u-margin-medium">
          <div className="warningBox">
            <p>
              新しい求人広告を投稿するには、右上の『メニュー』をクリックして『求人投稿・編集』を選んでください。
            </p>
          </div>
        </div>
      )}
      <div className="jobSearch__container">
        {useMap ? (
          <MapSearch onChange={handleMapChange} />
        ) : (
          <SearchResult pageSize={DEFAULT_PAGE_SIZE} />
        )}
        <PageLoading animationClass="fade" />
      </div>
    </div>
  );
}

export default JobSearch;
