import React from 'react';
import { Link } from 'react-router-dom';

import personalRegister from '../../../img/help/help_register_personal.jpg';
import businessRegister from '../../../img/help/help_register_business.jpg';


const HelpRegister = ({ business = false }) => {
    return (
        <div>
            <div className="line">・画面右上、『<Link to="/register">新規登録</Link>』を選択してアカウント作成画面に移動します</div>
            <div className="line">・アカウントタイプは『{business ? '企業・雇用主' : '求職者'}』を選択し、その他項目を入力してアカウントを作成してください</div>
            <div className="image">
                <img src={business ? businessRegister : personalRegister} />
            </div>
            <div className="line has-text-danger">※アカウントタイプは後で変更できません。間違えて作成した場合は、一度アカウントを削除して改めて新規登録してください。</div>
        </div>
    )
}

export default HelpRegister
