import React from 'react'
import { useTransition, animated } from 'react-spring';

const Collapse = ({ children, show, className }) => {
    const transitions = useTransition(show, null, {
        from: { opacity: 0, transform: 'scaleY(0)', maxHeight: '0px' },
        enter: { opacity: 1, transform: 'scaleY(1)', maxHeight: '10000px' },
        leave: { opacity: 0, transform: 'scaleY(0)', maxHeight: '0px' }
    })

    return transitions.map(({ item, key, props }) =>
        item && <animated.div key={key} style={props} className={className}>{children}</animated.div>)
}

export default Collapse
