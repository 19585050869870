import React from 'react';
import { FacebookProvider, Page } from 'react-facebook';

const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
const environment = process.env.REACT_APP_ENVIRONMENT;

export const FacebookPage = () =>
  environment != 'dev' &&
  <FacebookProvider appId={appId}>
    <Page href="https://www.facebook.com/nzworksjp/" />
  </FacebookProvider>

