import client from "../utils/client";
import Alert from "../utils/alert";
import errorToStr from "../utils/errorToStr";
import { SET_PERSONAL_PROFILE_LIST } from "./types";
import { setLoading } from "./commonActions";

const DEFAULT_PAGE_SIZE = 20;

const setPersonalProfileList = (profileList, itemCount) => {
  return {
    type: SET_PERSONAL_PROFILE_LIST,
    payload: { users: profileList, count: itemCount }
  };
};

export const getPersonalProfileList = (
  page = 1,
  size = DEFAULT_PAGE_SIZE
) => dispatch => {
  let params = {
    page: page,
    size: size,
    type: "Personal",
    is_public: "true"
  };
  setLoading(true);

  client
    .get("user", { params: params })
    .then(res => {
      const { count, users } = res.data;
      dispatch(setPersonalProfileList(users, count));
    })
    .catch(error => {
      console.log(error);
      Alert.error(errorToStr(error));
    })
    .finally(() => {
      setLoading(false);
    });
};
