import React from 'react'
import ThreadCard from '../thread/ThreadCard';

const LatestPostList = ({ postList }) => {
    return (
        <div className="latestPostList">
            {
                !postList || postList.length === 0
                    ? <p>データがありません</p>
                    : postList.map(thread => {
                        return (
                            <div key={thread._id} className="latestPostList__item u-margin-bottom-small">
                                <ThreadCard thread={thread} />
                            </div>

                        )
                    })
            }
        </div>
    )
}

export default LatestPostList
