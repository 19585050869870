import React from 'react'
import Collapse from '../common/Collapse';
import Icon from '../common/Icon';

const HelpPanel = ({ title, children, show }) => {
    const renderIcon = () => (
        show ?
            <Icon iconClassName='fas fa-chevron-down u-margin-right-small' />
            : <Icon iconClassName='fas fa-chevron-right u-margin-right-small' />
    );

    return (
        <div className="helpPanel">
            <div className="helpPanel__title">
                {
                    renderIcon()
                }
                {title}
            </div>
            <Collapse show={show} className="helpPanel__content">
                {children}
            </Collapse>
        </div>
    )
}

export default HelpPanel
