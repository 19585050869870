import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Linkify from "react-linkify";
import { getThread, getThreadComment } from "../../actions/threadActions";
import { setRecordInfo } from "../../actions/mailActions";
import history from "../../history";

import Image from "../common/Image";
import Spinner from "../common/Spinner";
import JobDetailCompanyInfo from "../job/JobDetail/JobDetailCompanyInfo";
import ThreadCommentArea from "./ThreadCommentArea";

const ThreadDetail = props => {
  const dispatch = useDispatch();
  const threadId = props.match.params.id;
  const currentUser = useSelector(state => state.user.currentUser);
  const threadDetail = useSelector(state => state.thread.selectedThread);
  const loading = useSelector(state => state.common.loading);

  useEffect(() => {
    if (threadId) {
      dispatch(getThread(threadId));
    }
    const interval = setInterval(() => {
      if (threadId) {
        dispatch(getThreadComment(threadId));
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [threadId, dispatch]);

  const handleSendMail = () => {
    dispatch(
      setRecordInfo({
        id: threadDetail._id,
        type: "thread",
        title: "RE:" + threadDetail.title,
        details: threadDetail.details,
        userName: threadDetail.user.name
      })
    );
    history.push("/mail");
  };

  const renderContent = () => {
    if (loading)
      return (
        <div className="threadDetail">
          <Spinner cover={true} />
        </div>
      );
    if (!threadDetail) return <div>No data</div>;

    return (
      <div className="threadDetail">
        <h1 className="heading u-margin-medium">{threadDetail.title}</h1>
        <div className="u-flex-responsive u-space-around u-margin-bottom-medium">
          <div className="u-flex-grow  u-margin-small">
            <h2 className="heading--label-primary">詳細</h2>
            {threadDetail.mainImage ? (
              <div className="threadDetail__image u-margin-small">
                <Image src={threadDetail.mainImage.image_url} />
              </div>
            ) : null}
            <div className="u-text-wrap">
              <Linkify>{threadDetail.details}</Linkify>
            </div>
          </div>

          <div className="threadDetail__userInfo">
            <div className="u-margin-small">
              <JobDetailCompanyInfo user={threadDetail.user} />
            </div>
            {threadDetail.email &&
              currentUser &&
              threadDetail.user._id !== currentUser._id ? (
              <button
                type="button"
                onClick={handleSendMail}
                className="button is-success u-margin-auto"
              >
                投稿者にメールを送る
              </button>
            ) : null}
          </div>
        </div>

        <section>
          <ThreadCommentArea
            threadId={threadDetail._id}
            commentList={threadDetail.comments}
            threadOwnerId={threadDetail.user._id}
            currentUserId={currentUser ? currentUser._id : null}
          />
        </section>
      </div>
    );
  };

  return <div className="container">{renderContent()}</div>;
};

export default ThreadDetail;
