//Common
export const SET_LOADING = "SET_LOADING";
export const SET_ERRORS = "SET_ERRORS";
export const SET_AREA_LIST = "SET_AREA_LIST";

//Home
export const SET_LATEST_JOBLIST = "SET_LATEST_JOBLIST";
export const SET_LATEST_FEEDLIST = "SET_LATEST_FEEDLIST";
export const SET_LATEST_POSTLIST = "SET_LATEST_POSTLIST";
export const SET_REGISTERED_COMPANY_LIST = "SET_REGISTERED_COMPANY_LIST";
export const SET_CANDIDATE_COUNT = "SET_CANDIDATE_COUNT";

//Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SIGN_OUT = "SIGN_OUT";

//User
export const SET_AVATAR = "SET_AVATAR";
export const SET_USERNAME = "SET_USERNAME";
export const SET_PROFILE = "SET_PROFILE";
export const SET_IMAGES = "SET_IMAGES";
export const SET_SAVED_JOB_LIST = "SET_SAVED_JOB_LIST";
export const SET_SAVED_JOB = "SET_SAVED_JOB";
export const ADD_SAVED_JOB = "ADD_SAVED_JOB";
export const REMOVE_SAVED_JOB = "REMOVE_SAVED_JOB";
export const SET_APPLY = "SET_APPLY";
export const DELETE_USER = "DELETE_USER";
export const LIKE_USER = "LIKE_USER";
export const UNLIKE_USER = "UNLIKE_USER";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const CHECK_NOTIFICATIONS = "CHECK_NOTIFICATIONS";

//Job
export const SET_JOB_SEARCH_FILTER = "SET_JOB_SEARCH_FILTER";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_JOB_SEARCH_RESULT = "SET_JOB_SEARCH_RESULT";

//Job Details
export const SET_JOB_DETAILS = "SET_JOB_DETAILS";
export const SET_USER_JOB_LIST = "SET_USER_JOB_LIST";

//Thread
export const SET_THREAD_LIST = "SET_THREAD_LIST";
export const SET_THREAD = "SET_THREAD";
export const SET_THREAD_COMMENT = "SET_THREAD_COMMENT";
export const SELECT_THREAD_COMMENT = "SELECT_THREAD_COMMENT";

//Profile
export const SET_PROFILE_LIST = "SET_PROFILE_LIST";
export const SET_LATEST_PROFILE_LIST = "SET_LATEST_PROFILE_LIST";
export const SET_PERSONAL_PROFILE_LIST = "SET_PERSONAL_PROFILE_LIST";

//Mail
export const MAIL_SET_RECORD_INFO = "MAIL_SET_RECORD_INFO";
export const MAIL_SET_SENDING = "MAIL_SET_SENDING";
export const MAIL_SET_ERRORS = "MAIL_SET_ERRORS";

//Admin page
export const ADMIN_SET_USER_LIST = "ADMIN_SET_USER_LIST";
export const ADMIN_SET_JOB_LIST = "ADMIN_SET_JOB_LIST";
export const ADMIN_SET_SAVED_JOB_LIST = "ADMIN_SET_SAVED_JOB_LIST";
