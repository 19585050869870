import React from 'react'
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery'

import { getResizedImageUrl } from '../../../utils/imageManager';
import JobDetailLocation from './JobDetailLocation';
import Icon from '../../common/Icon';

const JobDetailCompanyInfo = props => {
    if (!props.user) return <p>No data</p>;
    let { name, profile } = props.user;

    const renderCompanyImages = images => {
        if (!images || images.length === 0) return null;

        let items = [];

        images.forEach(image => {
            items.push({
                original: image.image_url,
                thumbnail: getResizedImageUrl(image.image_url, 'c_thumb,w_200')
            })
        })

        return (
            <ImageGallery
                items={items}
                autoPlay={true}
                slideInterval={6000}
                additionalClass="jobDetail__gallery"
            />
        )
    }

    return (
        <div className="jobDetail__companyInfo">
            <h3 className="heading--label-primary">投稿者情報</h3>
            <div className="jobDetail__companyInfo__body box--sub">
                <h4>{name}</h4>
                <div className="jobDetail__companyInfo__container">
                    <div className="jobDetail__companyInfo__images">
                        {renderCompanyImages(profile.images)}
                    </div>
                    <div className="jobDetail__companyInfo__description">
                        {profile.introduction}
                    </div>
                    {
                        profile.companyWebsite
                            ? (
                                <div className="u-margin-top-small">
                                    <h5>ウェブサイト</h5>
                                    <a href={profile.companyWebsite} className="has-text-link"><u>{profile.companyWebsite}</u></a>
                                    {
                                        profile.facebook ? <a href={profile.facebook}><Icon iconClassName="fab fa-facebook fa-2x icon--selectable has-text-link" className="is-large u-margin-left-small" /></a> : null
                                    }
                                    {
                                        profile.instagram ? <a href={profile.instagram}><Icon iconClassName="fab fa-instagram fa-2x icon--selectable has-text-danger" className="is-large u-margin-left-small" /></a> : null
                                    }
                                </div>
                            )
                            : null
                    }
                    <br />
                    <JobDetailLocation address={profile.address} />
                </div>
            </div>
        </div>
    )
}

JobDetailCompanyInfo.propTypes = {
    user: PropTypes.object
}

export default JobDetailCompanyInfo
