import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';

import { getPersonalProfileList } from '../../actions/personalProfileActions';
import { setRecordInfo } from '../../actions/mailActions';
import { likeUserProfile, unlikeUserProfile } from '../../actions/userActions';

import history from '../../history';
import Pagination from '../common/Pagination';
import Spinner from '../common/Spinner';
import Alert from '../../utils/alert';
import PersonalProfileCard from './PersonalProfileCard';
import { getNewNotificationNum } from '../../utils/getNotificationNum';

const DEFAULT_PAGE_SIZE = 20;

const PersonalProfileList = () => {
    const profileList = useSelector(state => state.personalProfile.personalProfileList);
    const itemCount = useSelector(state => state.personalProfile.itemCount);
    const loading = useSelector(state => state.common.loading);
    const currentUser = useSelector(state => state.user.currentUser);
    const notifications = useSelector(state => state.user.notifications);
    let notificationNum = getNewNotificationNum(notifications);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(getPersonalProfileList(1, DEFAULT_PAGE_SIZE));
    }, [dispatch])

    const handlePageClick = selectedPage => {
        window.scrollTo(0, 200);
        dispatch(getPersonalProfileList(selectedPage, DEFAULT_PAGE_SIZE));
    }

    const onLike = (event, userId) => {
        dispatch(likeUserProfile(userId));
    }

    const onUnlike = userId => {
        Alert.confirm('良いねを取り消しますか？')
            .then((result) => {
                if (result.value) {
                    dispatch(unlikeUserProfile(userId));
                }
            })
    }

    const onEmail = user => {
        dispatch(setRecordInfo({
            id: user._id,
            type: 'user',
            title: '',
            details: user.profile.introduction,
            userName: user.name
        }))
        history.push('/mail');
    }

    const renderProfileList = () => {
        if (!profileList) return null;

        return profileList.map(user => {
            let alreadyLiked = currentUser.likedUsers && currentUser.likedUsers.includes(user._id);

            return (
                <div key={user._id} className={`searchResult__item u-margin-bottom-medium ${alreadyLiked ? 'liked' : ''}`}>
                    <PersonalProfileCard user={user} />
                    <div className="buttons u-padding-small">
                        {
                            alreadyLiked
                                ? <button className="button is-warning" onClick={() => onUnlike(user._id)} type="button" disabled={loading ? 'disabled' : ''}><i className="fas fa-thumbs-up"></i>&nbsp;良いねを取り消す</button>
                                : <button className="button is-primary" onClick={(event) => onLike(event, user._id)} type="button" disabled={loading ? 'disabled' : ''}><i className="fas fa-thumbs-up"></i>&nbsp;良いね!</button>
                        }
                        <button className="button is-success" onClick={() => onEmail(user)} type="button"><i className="fas fa-envelope"></i>&nbsp;投稿者にメールを送る</button>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="personalProfileList">
            <Helmet>
                <title>{notificationNum ? `(${notificationNum})` : ''}求職者プロフィール</title>
                <meta name="description" content="ニュージーワークスに登録されている求職者アカウントの一覧です。（プロフィールを公開しているアカウントのみ表示されます）" />
            </Helmet>
            <div className="pageHeader">
                <h1 className="heading">求職者プロフィール</h1>
            </div>
            <div className="container">
                <p className="has-text-success">※プロフィールを公開しているアカウントのみ表示されます</p>
                <p className="has-text-success u-margin-bottom-small">※良いねボタンをクリックすると対象のユーザーに通知されます。プロフィールが募集中の求人とマッチしていて、応募を促す際等にご利用ください。</p>
                <div>
                    <div className="searchResult">
                        {
                            loading ? <Spinner cover={true} /> : null
                        }
                        {renderProfileList(profileList)}
                    </div>
                    <Pagination
                        itemCount={itemCount}
                        pageSize={DEFAULT_PAGE_SIZE}
                        onPageChange={handlePageClick}
                        className='bg-secondary-light'
                    />
                </div>
            </div>
        </div>
    )
}

export default PersonalProfileList
