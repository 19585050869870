import client from "../utils/client";
import Alert from "../utils/alert";
import errorToStr from "../utils/errorToStr";
import { SET_JOB_DETAILS, SET_USER_JOB_LIST } from "./types";
import { setLoading } from "./commonActions";

const setJobDetails = jobDetails => {
  return {
    type: SET_JOB_DETAILS,
    payload: jobDetails
  };
};

const setUserJobList = jobList => {
  return {
    type: SET_USER_JOB_LIST,
    payload: jobList
  };
};

export const getJobDetails = id => dispatch => {
  dispatch(setJobDetails(null));
  dispatch(setLoading());
  client
    .get("job/" + id)
    .then(response => {
      let jobDetails = response.data;
      dispatch(setJobDetails(jobDetails));
      dispatch(getUserJobList(id, jobDetails.user._id));
    })
    .catch(error => {
      Alert.error(errorToStr(error));
      dispatch(setLoading(false));
    });
};

export const getUserJobList = (id, userId) => dispatch => {
  client
    .get("job/user/" + userId + "?exclude=" + id)
    .then(response => {
      dispatch(setUserJobList(response.data));
    })
    .catch(error => {
      Alert.error(errorToStr(error));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
