import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import formatDate from '../../utils/formatDate';
import { deleteThread } from '../../actions/threadActions';
import Image from '../common/Image';
import Alert from '../../utils/alert';
import DeleteIcon from '../common/Icons/DeleteIcon';
import Icon from '../common/Icon';
import noImageUrl from '../../img/no_image_small.jpg';

const ThreadListItem = ({ thread }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const dispatch = useDispatch();

    const renderUserInfo = (user) => {
        return (
            <div className="threadList__item__userInfo">
                <figure className="image  u-margin-small">
                    {
                        user.profile.avatar ?
                            <Image className="is-rounded" src={user.profile.avatar.image_url} size={'200'} />
                            : <img className="is-rounded" src={noImageUrl} />
                    }
                </figure>
                <p><b>{user.name}</b></p>
            </div>
        )
    }

    const handleDeleteClick = id => {
        Alert.confirm("この投稿を本当に削除しますか？")
            .then((result) => {
                if (result.value) {
                    dispatch(deleteThread(id));
                }
            })
    }

    return (
        <div className="threadList__item">
            <Link to={`/thread/${thread._id}`} className="threadList__item__content">
                {
                    thread.mainImage ? (
                        <div className="threadList__item__image">
                            <Image src={thread.mainImage.image_url} size={'200'} />
                        </div>
                    ) : null
                }
                <div className="u-flex-grow">
                    {
                        currentUser && currentUser._id === thread.user._id
                            ? (
                                <div className="threadList__item__tools">
                                    <span className="tag is-warning is-medium">
                                        自分の投稿
                                    </span>
                                    <span className="threadList__item__tools__actions">
                                        <DeleteIcon onClick={() => handleDeleteClick(thread._id)} className='is-medium fa-lg' />
                                    </span>
                                </div>
                            )
                            : null
                    }
                    <h3>{thread.title}</h3>
                    <div className='u-flex-grow'>
                        {renderUserInfo(thread.user)}
                    </div>
                    <div className="threadList__item__info">
                        <div>投稿日:<b>{formatDate(thread.created_at)}</b></div>
                        <div><Icon iconClassName="fas fa-comment" className="has-text-primary u-margin-left-small" /> x <b>{thread.comments.length}</b></div>
                    </div>
                </div>

            </Link>
        </div>
    )
}

ThreadListItem.propTypes = {
    thread: PropTypes.object.isRequired
}

export default ThreadListItem

