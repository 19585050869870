import axios from 'axios';
import {
    SET_AVATAR,
    SET_PROFILE,
    SET_IMAGES,
    ADD_SAVED_JOB,
    REMOVE_SAVED_JOB,
    SET_APPLY,
    SIGN_OUT,
    SET_SAVED_JOB_LIST,
    SET_SAVED_JOB,
    SET_USERNAME,
    LIKE_USER,
    UNLIKE_USER,
    CHECK_NOTIFICATIONS,
    SET_NOTIFICATIONS
} from './types';
import { setLoading } from './commonActions';
import Alert from '../utils/alert';
import client from '../utils/client';
import errorToStr from '../utils/errorToStr';
import history from '../history';

const setAvatar = imageObj => {
    return {
        type: SET_AVATAR,
        payload: imageObj
    }
}

export const setUserName = name => {
    return {
        type: SET_USERNAME,
        payload: name
    }
}

const setProfile = profile => {
    return {
        type: SET_PROFILE,
        payload: profile
    }
}

const setImages = images => {
    return {
        type: SET_IMAGES,
        payload: images
    }
}

export const addSavedJob = savedJob => {
    return {
        type: ADD_SAVED_JOB,
        payload: savedJob
    }
}

const removeSavedJob = jobId => {
    return {
        type: REMOVE_SAVED_JOB,
        payload: jobId
    }
}

const setApply = jobId => {
    return {
        type: SET_APPLY,
        payload: jobId
    }
}

const setSavedJobList = jobList => {
    return {
        type: SET_SAVED_JOB_LIST,
        payload: jobList
    }
}

const setSavedJob = savedJob => {
    return {
        type: SET_SAVED_JOB,
        payload: savedJob
    }
}

const likeUser = userId => {
    return {
        type: LIKE_USER,
        payload: userId
    }
}

const unlikeUser = userId => {
    return {
        type: UNLIKE_USER,
        payload: userId
    }
}

/*-----------------------------------------------------------
 Export actions
------------------------------------------------------------*/
export const getUserImages = () => dispatch => {
    client.get('user/images')
        .then(response => {
            dispatch(setImages(response.data.images))
            dispatch(setAvatar(response.data.avatar))
        }).catch(error => {
            console.log(error);
        })
}

export const deleteUserImage = (image, isMain = false) => dispatch => {
    dispatch(setLoading());
    client.delete('user/images/' + encodeURIComponent(image.image_id))
        .then(response => {
            Alert.success('写真を削除しました');
            dispatch(setImages(response.data))
            if (isMain) {
                dispatch(setAvatar(null));
            }
        }).catch(error => {
            Alert.error(errorToStr(error));
        })
        .finally(() => {
            dispatch(setLoading(false));
        })
}

export const updateProfile = fd => dispatch => {
    if (!fd.avatar) fd.avatar = undefined;
    dispatch(setLoading());

    axios.all([
        client.patch('user/profile', fd),
        client.patch('user', { name: fd.name })
    ])
        .then(axios.spread((resProf, resUser) => {
            dispatch(setProfile(resProf.data));
            dispatch(setUserName(resUser.data.name));
            Alert.success('プロフィール情報を更新しました');
        })).catch(error => {
            Alert.error(errorToStr(error));
        })
        .finally(() => {
            dispatch(setLoading(false));
        })


    // client.patch('user/profile', fd)
    //     .then(response => {
    //         Alert.success('プロフィール情報を更新しました');
    //         dispatch(setProfile(response.data));
    //     }).catch(error => {
    //         Alert.error(errorToStr(error));
    //     })
    //     .finally(() => {
    //         dispatch(setLoading(false));
    //     })
}

export const getSavedJobList = () => dispatch => {
    client.get('savedjob/')
        .then(response => {
            dispatch(setSavedJobList(response.data));
        })
        .catch(error => {
            console.log(error);
        })
}

export const refleshSavedJob = jobId => dispatch => {
    client.get(`savedjob/${jobId}`)
        .then(response => {
            dispatch(setSavedJob(response.data))
        })
}

export const saveJob = (userId, jobId, applied = false) => dispatch => {
    client.post('savedjob/', { user: userId, job: jobId, applied: applied })
        .then(response => {
            dispatch(getSavedJobList());

        }).catch(error => {
            Alert.error(errorToStr(error));
        })
}

export const unsaveJob = id => dispatch => {
    client.delete('savedjob/' + id)
        .then(response => {
            dispatch(removeSavedJob(id));

        }).catch(error => {
            Alert.error(errorToStr(error));
        })
}

export const applyJob = (userId, jobId) => dispatch => {
    client.put('savedjob', { user: userId, job: jobId, applied: true })
        .then(response => {
            dispatch(setApply(jobId))
        })
        .catch(error => {
            console.log(error);
            Alert.error(errorToStr(error));
        })
}

export const likeUserProfile = userId => dispatch => {
    dispatch(setLoading());
    client.post(`user/${userId}/like`)
        .then(res => {
            dispatch(likeUser(userId));
        })
        .catch(error => {
            console.log(error);
            Alert.error(errorToStr(error));
        })
        .finally(() => {
            dispatch(setLoading(false));
        })
}

export const unlikeUserProfile = userId => dispatch => {
    dispatch(setLoading());
    client.delete(`user/${userId}/like`)
        .then(res => {
            dispatch(unlikeUser(userId));
        })
        .catch(error => {
            console.log(error);
            Alert.error(errorToStr(error));
        })
        .finally(() => {
            dispatch(setLoading(false));
        })
}

export const getNotifications = () => dispatch => {
    client.get('user/notification')
        .then(res => {
            dispatch({
                type: SET_NOTIFICATIONS,
                payload: res.data.reverse(),
            })
        })
        .catch(error => {
            console.log(error)
        })
}

export const checkNotifications = () => dispatch => {
    client.patch(`user/notification`)
        .then(res => {
            dispatch({
                type: CHECK_NOTIFICATIONS
            });
        })
        .catch(error => {
            console.log(error);
        })
}

export const deleteAccount = () => dispatch => {
    history.push("/");
    client.delete('user')
        .then(response => {
            Alert.success("アカウントを削除しました");
            dispatch({
                type: SIGN_OUT
            })
        })
        .catch(error => {
            Alert.error(errorToStr(error));
        })
}