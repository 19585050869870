import {
    SET_PERSONAL_PROFILE_LIST,
} from '../actions/types';

const INITIAL_STATE = {
    personalProfileList: [],
    itemCount: 0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PERSONAL_PROFILE_LIST:
            return {
                ...state,
                personalProfileList: action.payload.users,
                itemCount: action.payload.count
            }
        default:
            return state;
    }
}
