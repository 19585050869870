import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getLatestJobList,
  getLatestFeedList,
  getRegisteredCompanyList,
  getLatestPostList
} from "../../actions/homeActions";

import LatestJobList from "./LatestJobList";
import SeasonalWorkInfo from "./SeasonalWorkInfo";
import RegisteredCompaniesInfo from "./RegisteredCompaniesInfo";
import { FacebookPage } from "./FacebookPage";
import LatestPostList from "./LatestPostList";
import { getNewNotificationNum } from "../../utils/getNotificationNum";
import TwitterPage from "./TwitterPage";

function Home(props) {
  const dispatch = useDispatch();
  const latestJobList = useSelector(state => state.home.latestJobList);
  const latestPostList = useSelector(state => state.home.latestPostList);
  const notifications = useSelector(state => state.user.notifications);
  let notificationNum = getNewNotificationNum(notifications);

  useEffect(() => {
    dispatch(getLatestJobList());
    dispatch(getLatestPostList());
    dispatch(getLatestFeedList());
    dispatch(getRegisteredCompanyList());
  }, [dispatch]);

  return (
    <div className="home">
      <Helmet>
        <title>
          {notificationNum ? `(${notificationNum})` : ""}ＮＺワークス |
          ニュージーランドの求人検索サイト
        </title>
        <meta
          name="description"
          content="ニュージーランドの日本人向け求人情報を投稿・検索できます。スタッフ募集中の経営者の方、仕事を探している永住者、ワーキングホリデーメーカーいずれの方も是非ご利用ください！"
        />
      </Helmet>
      <header className="home__header">
        <div className="home__header__container">
          <h1>
            <span>ニュージーランドの</span>
            <span>求人情報サイト</span>
          </h1>
          <p className="home__header__content">
            ニュージーワークスはニュージーランド在住日本人向けの求人情報サイトです。
            <span>日本人スタッフを募集中の経営者、</span>
            <span>仕事を探している</span>
            <span>永住者・ワーホリメーカー、</span>いずれの方も
            <span>是非ご利用ください！</span>
          </p>
          <br />
          <div>
            <Link to="/signup" className="button is-primary">
              アカウント登録（無料）
            </Link>
          </div>
        </div>
      </header>
      {/* <section>
        <div className="infoBox u-margin-small">
          <div className="infoBox__item">
            <h4 className="has-text-danger">機能変更 (2022-9-3)</h4>
            <p>
              一つのアカウントで投稿できる広告を増やしました（最大１０件）
            </p>
          </div>
          <div className="infoBox__item">
            <h4 className="has-text-danger">不具合修正 (2021-2-24)</h4>
            <p>
              求人応募フォームが一時使用不能となっていた不具合を修正しました。
            </p>
          </div>
          <div className="infoBox__item">
            <h4 className="has-text-danger">不具合修正 (2020-6-10)</h4>
            <p>
              プロフィール（または企業紹介）編集中、時間が経つと編集中の項目が初期化されてしまう不具合を修正しました。
            </p>
          </div>
          <div className="infoBox__item">
            <h4>機能追加（企業アカウント） (2020-6-3)</h4>
            <p>
              求人広告リストに各求人の閲覧回数を表示するようにしました。右上メニュー『求人投稿・編集』から広告リストページへ移動し、ご確認ください。
            </p>
          </div>
          <div className="infoBox__item">
            <h4 className="has-text-danger">
              新機能追加 (2020-5-18) - 自由投稿板機能
            </h4>
            <p>
              企業アカウント、求職アカウント共にご利用可能な簡易情報投稿機能を追加しました。
            </p>
          </div>
          <div className="infoBox__item">
            <h4>バグ修正 (2020-5-13)</h4>
            <p>ユーザープロフィールが変更できない現象を修正しました</p>
          </div>
          <div className="infoBox__item">
            <h4>UIアップデート (2020-4-16)</h4>
            <p>求人投稿編集画面、企業紹介画面の表示方法等を改良しました</p>
          </div>
          <div className="infoBox__item">
            <h4 className="has-text-danger">
              新機能追加 (2020-3-8) - 求職者プロフィール公開機能
            </h4>
            <p>【求職者用アカウント】</p>
            <p>
              簡単なプロフィールを設定して企業・雇用主アカウントユーザーに対し公開する事ができます。
            </p>
            <p>
              プロフィールを公開しておくと、企業・雇用主アカウントユーザーがプロフィールに『良いね』をする事ができるようになり、また直接メールを送る事ができるようになります。（求職者アカウントユーザーからは他のユーザーのプロフィールは見れません）
            </p>
            <br />
            <p>
              機能を利用するには右上メニュ ⇒
              プロフィール・アカウント設定より、自己紹介文を設定した後、『プロフィールを公開する』を
              ON にしてください。
            </p>
            <p className="u-margin-top-small">【企業・雇用主アカウント】</p>
            <p>
              プロフィールを公開している求職者ユーザーの自己紹介文を見る事ができます。
            </p>
            <p>
              それぞれのプロフィールに対して『良いね』をする事ができ、またニュージーワークス経由でメールを送る事もできます。
            </p>
            <p>
              ナビゲーションメニューの『求職者リスト』から公開ユーザーのリストを表示できます。
            </p>
          </div>
          <div className="infoBox__item">
            <h4>不具合修正 (2020-2-23)</h4>
            <p>
              アカウント未作成時、『ログイン』⇒『Google
              アカウントでログイン』を選択した際にプロフィールデータが正常に作成されない不具合を修正しました。
            </p>
          </div>
          <div>
            <h4>新機能追加 (2020-1-29)</h4>
            <p>【企業・雇用主アカウント】</p>
            <p>求人情報のツイッター再告知機能を実装しました！</p>
            <p>
              『求人投稿・編集』ページ内、ツイッターボタンをクリックする事により求人内容をニュージーワークス専用ツイッターでツイートします。
            </p>
            <p>募集中の求人を改めて宣伝したい時にご利用ください。</p>
          </div>
        </div>
      </section> */}
      <div className="home__content">
        <div className="home__content__main u-margin-small">
          <section>
            <h2 className="heading">最近の求人</h2>
            <div className="container">
              <LatestJobList jobList={latestJobList} />
            </div>
          </section>
          <section>
            <h2 className="heading">最近の投稿</h2>
            <LatestPostList postList={latestPostList} />
          </section>
          {/* <section>
                <h2 className="heading">エリアから探す</h2>
            </section>
            <section>
                <h2 className="heading">職種から探す</h2>
            </section>
            <section>
                <h2 className="heading">様々な条件から探す</h2>
                <button type="button is-large is-primary">検索ページへ</button>
            </section> */}
        </div>
        <div className="home__content__side u-margin-small">
          <div className="u-margin-bottom-medium">
            <h3 className="heading--label-primary">登録企業情報</h3>
            <RegisteredCompaniesInfo />
          </div>
          <div>
            <h3 className="heading--label-primary u-margin-bottom-small">
              Facebook &amp; Twitter
            </h3>
            <FacebookPage />
            <TwitterPage />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
