import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Linkify from "react-linkify";
import PropTypes from "prop-types";
import ImageGallery from "react-image-gallery";

import { getResizedImageUrl } from "../../utils/imageManager";
import Image from "../common/Image";
import Icon from "../common/Icon";
import JobDetailLocation from "../job/JobDetail/JobDetailLocation";
import client from "../../utils/client";
import JobCard from "./BusinessProfileDetail/JobCard";
import noImageUrl from "../../img/no_image.jpg";
import Spinner from "../common/Spinner";

const BusinessProfileDetail = props => {
  // const { name, profile } = props.user;
  const userId = props.match.params.id;
  const [userDetails, setDetails] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [loadingJobList, setLoadingJobList] = useState(false);

  useEffect(() => {
    setDetails(null);
    setJobList([]);
    setLoadingProfile(true);
    setLoadingJobList(true);
    if (userId) {
      client
        .get("user/" + userId)
        .then(result => {
          setDetails(result.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoadingProfile(false);
        });

      client
        .get("job/user/" + userId)
        .then(result => {
          setJobList(result.data);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          setLoadingJobList(false);
        });

      client.patch(`user/${userId}/viewCount`);
    }
  }, [userId, setDetails, setLoadingJobList, setJobList, setLoadingProfile]);

  const renderJobList = () => {
    if (jobList.length === 0) {
      return (
        <div className="u-margin-medium">現在募集中の求人はありません</div>
      );
    }

    return jobList.map(job => {
      return (
        <Link
          to={`/jobs/${job._id}`}
          className="businessProfileDetail__jobList__item"
          key={job._id}
        >
          <JobCard job={job} />
        </Link>
      );
    });
  };

  const renderProfiles = () => {
    if (!userDetails) return <div>No data</div>;

    const { name, profile } = userDetails;

    return (
      <div>
        {profile.avatar ? (
          <div className="businessProfileDetail__avatar u-margin-bottom-medium">
            <Image
              src={profile.avatar ? profile.avatar.image_url : noImageUrl}
              alt="avatar"
            />
          </div>
        ) : null}
        <h2 className="heading u-margin-bottom-medium">{name}</h2>
        <div className="u-margin-medium">
          <div className="u-flex-responsive">
            <div className="businessProfileDetail__introduction u-flex-grow u-text-wrap u-margin-small">
              <Linkify>{profile.introduction}</Linkify>
            </div>
            {profile.images && profile.images.length > 1 ? (
              <div className="businessProfileDetail__images  u-margin-small">
                {renderImages(profile.images)}
              </div>
            ) : null}
          </div>
          {profile.companyWebsite ? (
            <div className="u-margin-small">
              <h4>ウェブサイト</h4>
              <a href={profile.companyWebsite} className="has-text-link">
                <u>{profile.companyWebsite}</u>
              </a>
              {profile.facebook ? (
                <a href={profile.facebook}>
                  <Icon
                    iconClassName="fab fa-facebook fa-2x icon--selectable has-text-link"
                    className="is-large u-margin-left-small"
                  />
                </a>
              ) : null}
              {profile.instagram ? (
                <a href={profile.instagram}>
                  <Icon
                    iconClassName="fab fa-instagram fa-2x icon--selectable  has-text-danger"
                    className="is-large u-margin-left-small"
                  />
                </a>
              ) : null}
            </div>
          ) : null}
          {profile.address ? (
            <div className="u-margin-small">
              <h4>住所</h4>
              <JobDetailLocation address={profile.address} />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderImages = images => {
    let items = [];

    images.forEach(image => {
      items.push({
        original: image.image_url,
        thumbnail: getResizedImageUrl(image.image_url, "c_thumb,w_200")
      });
    });

    return (
      <div className="box--sub">
        <ImageGallery
          items={items}
          autoPlay={true}
          slideInterval={6000}
          additionalClass="jobDetail__gallery"
        />
      </div>
    );
  };

  return (
    <div className="businessProfileDetail">
      <section className="businessProfileDetail__profile u-margin-bottom-small">
        {loadingProfile ? <Spinner /> : renderProfiles()}
      </section>
      <section className="u-margin-small">
        <h3 className="heading--label-primary">現在募集中の求人</h3>
        <div className="businessProfileDetail__jobList">
          {loadingJobList ? <Spinner /> : renderJobList()}
        </div>
      </section>
    </div>
  );
};

BusinessProfileDetail.propTypes = {
  // user: PropTypes.object
  id: PropTypes.number
};

export default BusinessProfileDetail;
