import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import client from "../../utils/client";
import Alert from "../../utils/alert";
import errorToStr from "../../utils/errorToStr";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { getUserList } from "../../actions/adminActions";

const DEFAULT_PAGE_SIZE = 50;

const AdminUserList = props => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [filter, setFilter] = useState({ unverified: false });

  const dispatch = useDispatch();
  const userList = useSelector(state => state.admin.userList);
  const itemCount = useSelector(state => state.admin.userCount);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserList(currentPage, DEFAULT_PAGE_SIZE, filter));
  }, [currentPage, filter, setLoading, dispatch]);

  useEffect(() => {
    setLoading(false);
  }, [userList, setLoading]);

  const handlePageClick = selectedPage => {
    setPage(selectedPage);
  };

  const handleVerifiedCheckChange = () => {
    let newFilter = { ...filter };
    newFilter.unverified = !filter.unverified;
    setFilter(newFilter);
    setPage(1);
  };

  const handleDeleteUser = id => {
    Alert.confirm("このユーザーを削除しますか？").then(result => {
      if (result.value) {
        setLoading(true);
        client
          .delete("admin/users/" + id)
          .then(response => {
            Alert.success("削除しました");
            dispatch(getUserList(currentPage, DEFAULT_PAGE_SIZE, filter));
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleBlockUser = id => {
    Alert.confirm("このユーザーを Block しますか？").then(result => {
      if (result.value) {
        setLoading(true);
        client
          .patch("admin/blockuser/" + id)
          .then(response => {
            Alert.success("Block しました");
            dispatch(getUserList(currentPage, DEFAULT_PAGE_SIZE, filter));
          })
          .catch(error => {
            Alert.error(errorToStr(error));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  const handleVerifyUser = id => {
    client
      .patch("admin/business/" + id + "/verify")
      .then(response => {
        Alert.success("承認 しました");
        dispatch(getUserList(currentPage, DEFAULT_PAGE_SIZE, filter));
      })
      .catch(error => {
        Alert.error(errorToStr(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderUserList = () => {
    if (userList.length === 0)
      return (
        <tr>
          <td>No data</td>
        </tr>
      );

    return userList.map(user => {
      return (
        <tr key={user._id} className="adminUserList__item">
          <th>{user.name}</th>
          <td>
            {user.profile.user_type === "Business" ? (
              <span className="has-text-success">{user.profile.user_type}</span>
            ) : (
              user.profile.user_type
            )}
          </td>
          <td>{user.email}</td>
          <td>
            {user.verified ? (
              <span>verified</span>
            ) : (
              <span className="has-text-danger">unverified</span>
            )}
          </td>
          <td>
            {user.is_active ? (
              <span>active</span>
            ) : (
              <span className="has-text-danger">inactive</span>
            )}
          </td>
          <td>{user.likedUsers ? user.likedUsers.length : 0}</td>
          <td>{user.created_at}</td>
          <td>
            <button
              className="button is-small is-primary"
              onClick={() => handleVerifyUser(user._id)}
              disabled={
                !user.profile.user_type === "Business" ||
                user.profile.companyVerified
                  ? "disabled"
                  : null
              }
            >
              承認
            </button>
          </td>
          <td>
            <button
              className="button is-small is-danger"
              onClick={() => handleDeleteUser(user._id)}
              disabled={user.verified ? "disabled" : null}
            >
              削除
            </button>
          </td>
          <td>
            <button
              className="button is-small is-danger"
              onClick={() => handleBlockUser(user._id)}
              disabled={!user.verified || !user.is_active ? "disabled" : null}
            >
              Block
            </button>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className={props.className}>
      <label className="checkbox">
        <input
          type="checkbox"
          checked={filter.unverified}
          onChange={handleVerifiedCheckChange}
        />
        Unverified user only
      </label>
      <div className="adminUserList">
        {loading ? (
          <Spinner />
        ) : (
          <table className="adminTable">
            <tbody>{renderUserList()}</tbody>
          </table>
        )}
      </div>
      <Pagination
        itemCount={itemCount}
        pageSize={DEFAULT_PAGE_SIZE}
        onPageChange={handlePageClick}
        className="bg-secondary-light"
      />
    </div>
  );
};

export default AdminUserList;
