import React from 'react';
import srcSelectMenu from '../../../img/help/help_select_menu.jpg';
import srcPersonalProfileMenu from '../../../img/help/help_personal_profile_menu.jpg';
import srcPersonalProfileUpdate from '../../../img/help/help_personal_profile_update.jpg';
import srcPersonalProfileView from '../../../img/help/help_personal_profile_view.jpg';

const HelpPersonalProfile = () => {
    return (
        <div>
            <div className="line">画面右上、メニューをクリックまたはタップします</div>
            <div className="image">
                <img src={srcSelectMenu} />
            </div>
            <hr />
            <div className="line">メニューから『プロフィール編集』を選択し、編集ページへ移動します。</div>
            <div className="image">
                <img src={srcPersonalProfileMenu} />
            </div>
            <hr />
            <div className="line">紹介文と希望勤務地を入力した後、画面下にある『変更を保存』ボタンを押して変更内容を保存してください。</div>
            <div className="image">
                <img src={srcPersonalProfileUpdate} />
            </div>
            <hr />
            <div className="line">プロフィール公開をオンにしていると、雇用主アカウントが紹介文を閲覧できるようになり、且つニュージーワークス経由でのメール送信が可能となります。</div>
            <div className="image">
                <img src={srcPersonalProfileView} />
            </div>
        </div>
    )
}

export default HelpPersonalProfile
