import React, { useState } from 'react';
import client from '../../utils/client';
import Alert from '../../utils/alert';
import errorToStr from '../../utils/errorToStr';

const SendNotifications = () => {
    const [message, setMessage] = useState('');
    const [user_type, setUserType] = useState('');

    const handleChange = e => {
        setMessage(e.currentTarget.value);
    }

    const handleTypeChange = e => {
        setUserType(e.currentTarget.value);
    }

    const handleClick = () => {
        let data = {
            message: message,
            user_type: user_type
        };

        Alert.confirm(`UserType:${user_type} にNotification を送信します。`)
        .then((result) => {
            if (result.value) {
                client.post('admin/sendNotifications', data)
                .then(res => {
                    Alert.success("Sent!!");
                })
                .catch(error => {
                    Alert.error(errorToStr(error));
                })
            }
        })
    }

    return (
        <form>
            <div className="field u-flex u-flex-align">
                <div>
                    <div className="control">
                        <select name="user_type" className="input u-margin-bottom-small" value={user_type} onChange={handleTypeChange}>
                            <option value=""></option>
                            <option value="Business">Business</option>
                            <option value="Personal">Personal</option>
                        </select>
                    </div>
                    <div className="control">
                        <textarea name="message" value={message} rows="5" cols="50" className="textarea" onChange={handleChange} />
                    </div>
                </div>
                <div className="control">
                    <button type="button" className="button is-primary u-margin-small" onClick={handleClick} >Send </button>
                </div>
            </div>
        </form>
    )
}

export default SendNotifications
