var axios = require('axios');
const apiBaseURL = process.env.REACT_APP_API_URL;

var axiosInstance = axios.create({
  baseURL: apiBaseURL,
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    let originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosInstance.get('/auth/token')
        .then(res => {
          return axiosInstance(originalRequest);
        })
        .catch(tokenError => {
          return Promise.reject(tokenError);
        })
    }
    else if (error.response.status === 503) {
      return Promise.reject("サーバーとの接続が一時的に途切れました。<br/>少し時間を置いてから、もう一度お試しください。");
    }
    else {
      return Promise.reject(error);
    }
  }
)

module.exports = axiosInstance;