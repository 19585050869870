import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import TextField from '../form/TextField';

const MailForm = ({ onSubmit, name, email, title }) => {
    const methods = useForm(); // initialise the hook
    const { handleSubmit, setValue } = methods;

    useEffect(() => {
        setValue('name', name);
        setValue('email', email);
        setValue('title', title);
    }, [name, email, title, setValue])

    const submitFormData = data => {
        onSubmit(data);
    }

    return (
        <div>
            <FormContext {...methods} >
                <form onSubmit={handleSubmit(submitFormData)}>
                    <TextField
                            label="件名"
                            type="text"
                            placeholder="件名を入力してください"
                            name="title"
                            className="field u-margin-small"
                            registerOptions={{ required: true, maxLength: 100 }}
                    />
                    <div className="u-flex-responsive">
                        <TextField
                            label="送信者名"
                            type="text"
                            placeholder="送信者名を入力してください"
                            name="name"
                            className="field u-margin-small"
                            registerOptions={{ required: true, maxLength: 100 }}
                        />
                        <TextField
                            label="返信用メールアドレス"
                            type="email"
                            placeholder="メールアドレスを入力してください"
                            name="email"
                            className="field u-margin-small"
                            info="広告主が返信する際に利用する為、アドレスに間違いが無いか必ずご確認ください"
                            registerOptions={{ required: true, maxLength: 100 }}
                        />
                    </div>

                    <TextField
                        label="メッセージ"
                        type="textarea"
                        name="message"
                        className="field u-margin-small"
                        rows={15}
                        registerOptions={{ required: true, maxLength: 5000 }}
                    />
                    <div className="field">
                        <div className="control has-text-centered u-margin-medium">
                            <button type="submit" className="button is-link is-large">送信</button>
                        </div>
                    </div>
                </form>
            </FormContext>
        </div>
    )
}

MailForm.propTypes = {
    onSubmit: PropTypes.func,
    email: PropTypes.string,
    title: PropTypes.string
}

export default MailForm
