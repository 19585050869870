import { combineReducers } from "redux";
import userReducer from "./userReducer";
import commonReducer from "./commonReducer";
import jobReducer from "./jobReducer";
import homeReducer from "./homeReducer";
import profileReducer from "./profileReducer";
import personalProfileReducer from "./personalProfileReducer";
import adminReducer from "./adminReducer";
import threadReducer from "./threadReducer";
import mailReducer from "./mailReducer";
import jobDetailReducer from "./jobDetailReducer";

export default combineReducers({
  user: userReducer,
  profile: profileReducer,
  personalProfile: personalProfileReducer,
  home: homeReducer,
  job: jobReducer,
  jobDetails: jobDetailReducer,
  thread: threadReducer,
  common: commonReducer,
  mail: mailReducer,
  admin: adminReducer
});
